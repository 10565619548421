import FormBuilder from "components/Inputs/FormBuilder";
import { DataFields } from "./DataFields";

export default function CarouselModule({ dataInstance, register, errors, changeDataBoolean, changeDataFunction, setFieldImageManager, openImageManager, changeCompletePicker }) {

  return (<>
    <FormBuilder 
      dataDields={DataFields}
      dataInstance={dataInstance}
      register={register}
      errors={errors}
      changeDataBoolean={changeDataBoolean}
      changeDataFunction={changeDataFunction}
      setFieldImageManager={setFieldImageManager}
      openImageManager={openImageManager}
      changeCompletePicker={changeCompletePicker}
    />
  </>)
}