export const DataFields = [
  {
    name: "Categorías",
    fields: [
      {
        id: "fontSizeNameCategory",
        name: "Nombre de la categoría",
        type: "number",
        required: true,
        defaultValue: 38,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizeDescriptionCategory",
        name: "Descripción de la categoría",
        type: "number",
        required: true,
        defaultValue: 24,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizeFooterCategory",
        name: "Pie de categoría",
        type: "number",
        required: true,
        defaultValue: 20,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      }
    ]
  },
  {
    name: "Subcategorías",
    fields: [
      {
        id: "fontSizeNameSubcategory",
        name: "Nombre de la subcategoría",
        type: "number",
        required: true,
        defaultValue: 32,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizeDescriptionSubcategory",
        name: "Descripción de la subcategoría",
        type: "number",
        required: true,
        defaultValue: 24,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizeFooterSubcategory",
        name: "Pie de subcategoría",
        type: "number",
        required: true,
        defaultValue: 20,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      }
    ]
  },
  {
    name: "Productos",
    fields: [
      {
        id: "fontSizeNameProduct",
        name: "Nombre del producto",
        type: "number",
        required: true,
        defaultValue: 28,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizeDescriptionProduct",
        name: "Descripción del producto",
        type: "number",
        required: true,
        defaultValue: 24,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizeNameVariantProduct",
        name: "Nombre de la variante del producto",
        type: "number",
        required: true,
        defaultValue: 28,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizeDescriptionVariantProduct",
        name: "Descripción de la variante del producto",
        type: "number",
        required: true,
        defaultValue: 24,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "fontSizePriceVariantProduct",
        name: "Precio de la variante del producto",
        type: "number",
        required: true,
        defaultValue: 28,
        inputProps: { min: 10, max: 60 },
        endAdornment: { value: "px" },
        startAdornment: null,
        size: "small"
      }
    ]
  },
]