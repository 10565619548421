
const Card1 = ({ image, info, actions }) => {
  return (
    <div className="card1">
      <div className="image"><img src={ image } /></div>
      <div className="info-container">
        <div className="info">{ info }</div>
      </div>
      <div className="actions-container" >{ actions }</div>
    </div> 
  );
}

export default Card1;