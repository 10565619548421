import { Button, Tooltip } from "@mui/material";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
//import QRCode from 'react-qr-code'
import { QRCodeCanvas } from "qrcode.react";

export default function QR({ open, close, instance }) {
  
  const handleClose = () => { close() }

  const Actions = () => (
    <Button onClick={handleClose}>Cerrar</Button>
  )

  const downloadQR = (type) => {
    let type_dataurl = "image/png"
    let extension = "png"
    switch (type) {
      case "png":
        type_dataurl = "image/png"
        extension = "png"
        break;
      case "jpg":
        type_dataurl = "image/jpg"
        extension = "jpg"
        break;
      case "webp":
        type_dataurl = "image/webp"
        extension = "webp"
        break;    
      default:
        break;
    }

    const canvas = document.getElementById("download-1080");
    const pngUrl = canvas
      .toDataURL(type_dataurl)
      //.replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `download-1080.${extension}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <FlexibleWidthDialog open={open} title="Código QR" actions={<Actions />}>
      <div style={{ display: "none" }} >
        <QRCodeCanvas 
          id="download-1080" 
          value={`https://grupoargentilia.inteliqr.app/menus/${instance.slug}`} 
          size={1080} 
          level={"H"}
          includeMargin={true}
        />
      </div>
      <div style={{ textAlign: "center" }} >
        {/* <QRCode value={`https://grupoargentilia.inteliqr.app/menus/${instance.slug}`} size={256} /> */}
        <QRCodeCanvas
          value={`https://grupoargentilia.inteliqr.app/menus/${instance.slug}`}
          size={256}
          level={"H"}
          includeMargin={true}
        />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 5 }}>
          <Tooltip title="Descargar imagen png de 1080px x 1080px" arrow>
            <Button variant="contained" onClick={() => downloadQR("png")}> Descargar PNG</Button>
          </Tooltip>
          <Tooltip title="Descargar imagen jpg de 1080px x 1080px" arrow>
            <Button variant="contained" onClick={() => downloadQR("jpg")}> Descargar JPG</Button>
          </Tooltip>
          <Tooltip title="Descargar imagen webp de 1080px x 1080px" arrow>
            <Button variant="contained" onClick={() => downloadQR("webp")}> Descargar WEBP</Button>
          </Tooltip>
        </div>
      </div>
    </FlexibleWidthDialog>
  )
}