import { Divider, Typography } from "@mui/material";
import { ColorGradientInputC, ColorInputC, ImageInputC, SwitchC, TextInputC } from "components/Inputs";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export default function FormBuilder({
  dataDields,
  dataInstance, 
  register, 
  errors, 
  changeDataBoolean, 
  changeDataFunction = null, 
  setFieldImageManager, 
  openImageManager, 
  changeCompletePicker,
  changeColorGradient
}) {

  const checkInstanceAndProp = (property) => {
    return dataInstance !== undefined && dataInstance[property] !== undefined
  }

  return (<>
    { dataDields.map((block, indexBlock) => {
      return (
        <div key={`${block.name}-${indexBlock}`}>
          <Typography style={{ margin: "12px 0px", textTransform: "uppercase" }}>{block.name}</Typography>
          { block.fields.map((field, indexField) => {
            return (<div key={`${field.id}-${indexField}`}>
              { field.type === "number" || field.type === "text" ? <>
              <div className={field.type === "number" ? "container-label-input-2-col" : ""} style={{margin: "8px 0px" }}>
                <TextInputC {...field}
                  instance={dataInstance}
                  checkIfPropertyIsInInstance={checkInstanceAndProp}
                  register={register}
                  errors={errors}
                  changeDataFunction={changeDataFunction}
                />
                <ValidateErrorMessage message={errors[field.id]?.message} />
              </div>
              </> : null }
              { field.type === "image" ? <>
              <div style={{margin: "12px 0px 8px 0px" }}>
                <ImageInputC {...field}
                  instance={dataInstance}
                  checkIfPropertyIsInInstance={checkInstanceAndProp}
                  register={register}
                  errors={errors}
                  changeDataFunction={changeDataFunction}
                  setFieldImageManager={setFieldImageManager}
                  openImageManager={openImageManager}
                />
                <ValidateErrorMessage message={errors[field.id]?.message} />
              </div>
              </> : null }
              { field.type === "color" ? <>
              <div className="container-label-input-2-col" style={{margin: "8px 0px 16px 0px"}}>
                <ColorInputC {...field}
                  instance={dataInstance}
                  checkIfPropertyIsInInstance={checkInstanceAndProp}
                  register={register}
                  errors={errors}
                  changeDataFunction={changeDataFunction}
                  changeCompletePicker={changeCompletePicker}
                />
                <ValidateErrorMessage message={errors[field.id]?.message} /> 
              </div>
              </> : null }
              { field.type === "switch" ? <>
              <div className="container-label-input-2-col" style={{margin: "8px 0px" }}>
                <SwitchC {...field}
                  instance={dataInstance}
                  checkIfPropertyIsInInstance={checkInstanceAndProp}
                  register={register}
                  errors={errors}
                  changeDataFunction={changeDataBoolean}
                />
                <ValidateErrorMessage message={errors[field.id]?.message} /> 
              </div>
              </> : null }
              { field.type === "gradient" ? <>
              <div style={{marginBottom: 24 }}>
                <ColorGradientInputC {...field}
                  instance={dataInstance}
                  checkIfPropertyIsInInstance={checkInstanceAndProp}
                  changeColorGradient={changeColorGradient}
                />
                <ValidateErrorMessage message={errors[field.id]?.message} /> 
              </div>
              </> : null }
            </div>)
          })}
        </div>
      )
    })}
  </>)
}