
const RowTag = ({ info, actions }) => {
  return (
    <div className="row-tag">
      <div className="info-container">
        <div className="info">{ info }</div>
      </div>
      <div className="actions-container" >{ actions }</div>
    </div> 
  );
}

export default RowTag;