import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { ValidationSchema } from "../services/SchemaValidation";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { edit } from "../services/api";
import DialogImageManager from "routes/ImageManager/DialogImageManager";
import FormBuilder from "components/Inputs/FormBuilder";
import { DataFields } from "../Add/DataFields";

export default function Edit({ open, close, menu, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  useEffect(() => {
    if(open) {
      setDataInstance(instance)
      reset(instance)
    }
  }, [open])

  const mutation = useMutation(edit, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })
  

  const handleEdit = (params) => {
    setIsLoading(true)
    let id = params._id
    delete params._id
    delete params.deleted
    delete params.createdAt
    delete params.updatedAt
    mutation.mutate({ id, params })
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({...dataInstance, [name]: !dataInstance[name]}) 
  }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }  
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Editar historia - ${instance.name}`} actions={<Actions />}>
      <>
        <input type="hidden" name="menu" {...register(`menu`)} value={menu}/>
        <ValidateErrorMessage message={errors.menu?.message} />
        <FormBuilder
          dataDields={DataFields}
          dataInstance={dataInstance}
          register={register}
          errors={errors}
          changeDataFunction={handleOnChangeData}
          changeDataBoolean={handleChangeBoolean}
          openImageManager={handleOpenImageManager}
          setFieldImageManager={handleFieldImageManager} />
      </>
    </FlexibleWidthDialog>
    <DialogImageManager open={ imageManagerModalOpen } 
      actionClose={ handleCloseImageManager } 
      setImageSelected={ handleSelectImageManager } />
  </>)
}