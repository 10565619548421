import FormBuilder from "components/Inputs/FormBuilder";
import { DataFields } from "./DataFields";

export default function SeparatorModule({ dataInstance, register, errors, changeDataFunction, changeColorGradient }) {

  return (<>
    <div style={{ background: dataInstance.separatorColor !== undefined ? dataInstance.separatorColor.style: "", height: `${dataInstance.separatorSize}px`, marginBottom: 16 }}></div>
    
    <FormBuilder 
      dataDields={DataFields}
      dataInstance={dataInstance}
      register={register}
      errors={errors}
      changeDataFunction={changeDataFunction}
      changeColorGradient={changeColorGradient}
    />
  </>)
}