import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().nullable(),
  footer: Yup.string().nullable(),
  icon: Yup.string().nullable(),
  image: Yup.string().when('showImage', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  headerImage: Yup.string().when('showHeaderImage', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  video: Yup.string().when('showVideo', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  comment: Yup.string().nullable(),
  showImage: Yup.boolean().nullable(),
  showHeaderImage: Yup.boolean().nullable(),
  showVideo: Yup.boolean().nullable(),
  menu: Yup.string().required("Campo requerido"),
  active: Yup.boolean().required("Campo requerido"),
})