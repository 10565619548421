import React from "react";
import { CssBaseline, Grid, Paper, Toolbar, Box, Container } from "@mui/material";
const Employee = () => {
  return (
    <>
      <CssBaseline />

      <Box component="main">
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className="peperdashboard" sx={{ p: 1 }}>
                <h1>Hola soy el componente de Empleados</h1>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Employee;
