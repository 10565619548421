import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().nullable(),
  image: Yup.string().nullable(),
  company: Yup.string().required("Campo requerido"),
  active: Yup.boolean().required("Campo requerido"),
})

export const ValidationSchemaStyle = Yup.object().shape({
  backgroundColorHeader: Yup.string().required("Campo requerido"),
  backgroungImageHeader: Yup.string().nullable(),
  primaryColor: Yup.string().required("Campo requerido"),
  separatorSize: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  separatorColor: Yup.mixed().nullable(),
  fontFaceFile: Yup.string().nullable(),
  fontFaceName: Yup.string().nullable(),
  textColorBody: Yup.string().nullable(),
  backgroungImageBody: Yup.string().nullable(),
  backgroungImageCategories: Yup.string().nullable(),
  textColorCategories: Yup.string().nullable(),
  activeIndicatorColorCategories: Yup.string().nullable(),
  colorArrowsCategories: Yup.string().nullable(),
  colorDotsCategories: Yup.string().nullable(),
  isSameFontFaceBody: Yup.boolean(),  
  fontSizePCTextCategories: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeMovilTextCategories: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  textColorCategories: Yup.string().nullable(),
  fontFaceFileCategories: Yup.string().nullable(),
  fontFaceNameCategories: Yup.string().nullable(),
  fontSizeNameCategory: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeDescriptionCategory: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeFooterCategory: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeNameSubcategory: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeDescriptionSubcategory: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeFooterSubcategory: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeNameProduct: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeDescriptionProduct: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeNameVariantProduct: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizeDescriptionVariantProduct: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
  fontSizePriceVariantProduct: Yup.number().typeError("Campo requerido").required("Campo requerido").min(10, "El valor debe ser mayor o igual a 10").max(60, "El valor debe ser menor o igual a 60"),
})