import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false)
  const [globalUser, setGlobalUser] = useState({})
  const [globalToken, setGlobalToken] = useState(false)
  const [titlePage, setTitlePage] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('mqadtk')
    const usr = localStorage.getItem('mqadusr')
    if(token !== null) {
      setIsAuth(true)
      setGlobalToken(token)
      setGlobalUser(usr)
    }
  }, [])

  const value = {
    isAuth,
    activateAuth: () => {
      setIsAuth(true)
      ////console.log("token: ",  localStorage.getItem('mqadtk'))
      const token = localStorage.getItem('mqadtk')
      if(token !== null) {
        setIsAuth(true)
        setGlobalToken(token)
      }
      //window.location.reload()
    },
    logOut: () => {
      setIsAuth(false)
      setGlobalUser(false)
      setGlobalToken(false)
      localStorage.clear()
      //window.location.reload()
    },
    globalUser,
    loadUser: (data) => {
      setGlobalUser(data)
      localStorage.setItem('mqadusr', data);
    },
    globalToken,
    loadToken: (data) => {
      setGlobalToken(data)
      localStorage.setItem('mqadtk', data)
    },
    titlePage, 
    setTitlePage
  }

  return <AuthContext.Provider value={ value }>
          { children }
        </AuthContext.Provider>
}