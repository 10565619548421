import React, { useContext } from "react";
import { BrowserRouter, Routes, Route,/*, Navigate*/ 
useNavigate} from "react-router-dom";
import ThemeMUIProvider from "theme/MainTheme";
import Layout from "./components/Layout";
import Error from "./components/Error/Error";
import Dashboard from "./routes/Dashboard";
import Company from "./routes/Company";
import Menu from "./routes/Menu";
import Employee from "./routes/Employee";
import Role from "./routes/Role";
import Login from "./routes/Login";
import Builder from "routes/Builder";
import { AuthContext } from "context/AuthContext";
import ProductTag from "routes/ProductTag";
import Stories from "routes/Stories";

const ProtectRoute = ({children}) => {
  const navigate = useNavigate()
  const { isAuth } = useContext(AuthContext)
  if (!isAuth) { navigate("/admin") }
  return children
}

const App = () => {
  return (
    <ThemeMUIProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/admin/*" element={ <Layout /> }>
            <Route index element={ <Login /> } />
            <Route path="dashboard" element={ <ProtectRoute> <Dashboard /> </ProtectRoute> } />
            <Route path="empresas" element={ <ProtectRoute> <Company /> </ProtectRoute> } />
            <Route path="roles" element={ <ProtectRoute> <Role /> </ProtectRoute> } />
            <Route path="empleados" element={ <ProtectRoute> <Employee /> </ProtectRoute> } />
            <Route path="menus" element={ <ProtectRoute> <Menu /> </ProtectRoute> } />
            <Route path="etiquetas-producto" element={ <ProtectRoute> <ProductTag /> </ProtectRoute> } />
            <Route path="constructor" element={ <ProtectRoute> <Builder /> </ProtectRoute> } />
            <Route path="promo-slider" element={ <ProtectRoute> <Stories/> </ProtectRoute> } />
          </Route>
          <Route path="*" element={ <Error /> } />
        </Routes>
      </BrowserRouter>
    </ThemeMUIProvider>
  );
};

export default App;
