export const DataFields = [
  {
    name: "",
    fields: [
      {
        id: "name",
        name: "Nombre",
        type: "text",
        required: true,
        defaultValue: "",
        size: "small"
      },
      {
        id: "description",
        name: "Descripción",
        type: "text",
        required: true,
        defaultValue: "",
        size: "small"
      },
      /* {
        id: "content",
        name: "Contenido",
        type: "text",
        required: false,
        defaultValue: "",
        size: "small"
      }, */
      {
        id: "url",
        name: "Imagen",
        type: "image",
        required: false,
        isImage: true,
        defaultValue: "",
        size: "small"
      },
      {
        id: "duration",
        name: "Duración",
        type: "number",
        required: true,
        defaultValue: 3000,
        inputProps: { min: 1000, max: 60000 },
        endAdornment: { value: "ms" },
        startAdornment: null,
        size: "small"
      },
      {
        id: "active",
        name: "Activar historia",
        type: "switch",
        required: true,
        defaultValue: false,
        size: "small"
      },
    ]
  }
]