import { useEffect, useState } from "react";
import { Button, Skeleton } from "@mui/material";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { useQuery, useQueryClient } from "react-query";
import { getImageMenus } from "services/api/ImageManagerMenu";
import { getImageListByMenu } from "services/api/ImageList";
import ImageCategory from "./modules/ImageMenu";
import ImageList from "./modules/ImageList";

export default function DialogImageManager({ open, actionClose, setImageSelected }) {
  const queryClient = useQueryClient()
  const [selectedImageCategory, setSelectedImageCategory] = useState(null)
  const [imageList, setImagesList] = useState(null)
  const [selectedImageList, setSelectedImageList] = useState(null)
  const [disableMainButtons, setDisableMainButtons] = useState(false)
  const { isLoading, isFetching, error, data: dataImageCategories } = useQuery('imageCategoriesList', 
    getImageMenus,
    { refetchOnWindowFocus: false, enabled: open }
  ) 
  const { isLoading: isLoadingIL, isFetching: isFetchingIL, refetch: refetchIL, error: errorIM, data: dataImageList } = useQuery(['imageList', selectedImageCategory], 
    () => getImageListByMenu(selectedImageCategory._id),
    { refetchOnWindowFocus: false, enabled: false }
  ) 

  useEffect(() => {
    if(dataImageList !== undefined && dataImageList !== null && dataImageList !== "")
      setImagesList(dataImageList.data)
  }, [dataImageList])
  
  const handleReturnToImageCategory = () => {
    setSelectedImageCategory(null)
    setImagesList(null)
  }

  const handleCloseDialogImageManager = () => {
    setSelectedImageCategory(null)
    setSelectedImageList(null)
    setImagesList(null)
    actionClose(false)
  }

  const handleRefresData = () => { queryClient.invalidateQueries('imageCategoriesList') }

  const handleResetActions = (action) => {
    if(action === "addImageList") refetchIL()
    if(action === "removeImageList") refetchIL()
  }

  const handleWatchAction = (action, cancel, error, success) => {
    if(cancel) return;
    if(!success) { alert(error.message) } else { 
      alert("Acción realizada correctamente"); handleRefresData(); handleResetActions(action)
    }
  }

  const handleReturnImageSelected = () => {
    setImageSelected(selectedImageList.url)
    handleCloseDialogImageManager()
  }

  if(error) return <div> Ha ocurrido un error </div>

  const Actions = () => ( <>
    <Button onClick={ handleCloseDialogImageManager } disabled={disableMainButtons} >Cerrar</Button>
    { selectedImageList !== null && <Button onClick={ handleReturnImageSelected } variant="contained" disabled={disableMainButtons}>Establecer imagen</Button> }
  </> )

  return ( <>
    <FlexibleWidthDialog maxWidth="lg" title="Biblioteca de imagenes" open={ open } actions={ <Actions /> } >
      <div className="im-main-container">
      { (isLoading || isFetching) ?
        <div className="im-images-container">
          { [...Array(10).keys()].map((item, index) => 
          <div className="im-image-card" key={ index }>
            <Skeleton variant="rectangular" width="100%" height={87} />
          </div>
          ) }
        </div>
      : <>
        <div className="im-image-categories-container">
        { (dataImageCategories !== null && imageList === null) && <>
          <ImageCategory 
            dataImageCategories={ dataImageCategories } 
            selectedImageCategory={ selectedImageCategory }
            setSelectedImageCategory={ setSelectedImageCategory }
            imageList={ imageList } 
            setImageList={ setImagesList } 
            reportAction={ handleWatchAction }
          />
        </> }
        { (selectedImageCategory !== null && imageList !== null) && <>
          <ImageList
            imageCategory={ selectedImageCategory }
            dataImageList={imageList}
            selectedImageList={ selectedImageList }
            setSelectedImageList={ setSelectedImageList }
            returnImageCategory={ handleReturnToImageCategory }
            setDisableMainButtons={ setDisableMainButtons }
            reportAction={ handleWatchAction }
          />
        </> }
        </div>
      </> }
      </div>
    </FlexibleWidthDialog>
  </> )
}