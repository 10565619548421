import { Typography } from "@mui/material";
import { TextInputC } from "components/Inputs";
import FormBuilder from "components/Inputs/FormBuilder";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { DataFields } from "./DataFields";

export default function FontSizeModule({ dataInstance, register, errors, changeDataFunction }) {

  /* const checkInstanceAndProp = (property) => {
    return dataInstance !== undefined && dataInstance[property] !== undefined
  } */

  return (<>
    <FormBuilder 
      dataDields={DataFields}
      dataInstance={dataInstance}
      register={register}
      errors={errors}
      changeDataFunction={changeDataFunction}
    />
    {/* { DataFields.map((block, indexBlock) => {
      return (
        <div key={`${block.name}-${indexBlock}`}>
          <Typography>{block.name}</Typography>
          { block.fields.map((field, indexField) => {
            return (<div key={`${field.id}-${indexField}`}>
              { field.type === "number" &&
              <div className="container-label-input-2-col">
                <TextInputC {...field} 
                  instance={dataInstance}
                  checkIfPropertyIsInInstance={checkInstanceAndProp}
                  register={register}
                  errors={errors}
                  changeDataFunction={changeDataFunction}
                />
                <ValidateErrorMessage message={errors[field.id]?.message} /> 
              </div>
              }
            </div>)
          })}
          { indexBlock + 1 < DataFields.length && <hr/> }
        </div>
      )
    })} */}
  </>)
}