import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getImageMenus = () =>
  fetch(
    `${API_BASE_URL}/image-manager-menu`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  .then(res => res.json())

export const saveImageMenu= async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/image-manager-menu`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  );
  return await res.json();
}

export const updateImageMenu= async (id, params) => {
  const res = await fetch(
    `${API_BASE_URL}/image-manager-menu/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  );
  return await res.json();
}

export const deleteImageMenu= async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/image-manager-menu/${id}`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  );
  return await res.json();
}