import * as React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';

export default function ImageCard({ image="", name, description, active, actions }) {
  return (
    <Card sx={{ display: 'flex' }}>
      <CardMedia component="img" sx={{ width: 151, aspectRatio: "16/9"}} image={image} alt={name} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <Typography component="div" variant="h5">{name}</Typography>
          <ModeStandbyIcon sx={{ color: active ? "#00ff0a" : "#ff9800"}} />
          </div>
          <Typography variant="subtitle1" color="text.secondary" component="div"> {description} </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}> { actions } </Box>
      </Box> 
    </Card>
  );
}