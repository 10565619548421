import { useState } from "react";
import { Button, CircularProgress, IconButton, InputLabel, Switch, TextField } from "@mui/material";
import { useMutation } from 'react-query'
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { duplicate } from "../services/api";

export default function Duplicate({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [menuName, setMenuName] = useState(null)

  const mutation = useMutation(duplicate, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction(false, 'Error al guardar los datos', 'error')
      } else { 
        setMenuName(null);
        reportAction(true, 'Acción realizada correctamente', 'success') 
      }
    }
  })

  const handleDuplicate = () => {
    setIsLoading(true)
    mutation.mutate({ menuId: instance._id, name: menuName })
  }
  
  const handleClose = () => { setMenuName(null); close() }

  const handleOnChangeData = (event) => setMenuName(event.target.value)

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Duplicar menu "${instance.name}"`} actions={<Actions />}>
      <>
        <InputLabel htmlFor='name' style={{ fontSize: 14 }}>Nombre del nuevo menú</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name' 
          value={menuName}
          onChange={handleOnChangeData} />
        <br />
        { (menuName !== undefined && menuName !== null && menuName !== "" && !isLoading) ? 
        <Button variant="contained" color="info" onClick={handleDuplicate}>Duplicar</Button>
        : null }
      </>
    </FlexibleWidthDialog>
  </>)
}