import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItems } from "./MenuData";
import { AuthContext } from "context/AuthContext";

const useStyles = makeStyles((theme) => ({
  optionDrawer: {
    textDecoration: 'none',
    color: '#000000'
  },
  toolbar: theme.mixins.toolbar,
}))

const Menu = ({ action }) => {
  const { logOut, globalUser } = useContext(AuthContext)
  const classes = useStyles()
  const location = useLocation()
  const usr = JSON.parse(localStorage.getItem('mqadusr'))

  return (
    <>
    <Box
      className={classes.toolbar}
      sx={{ width: 251 }}
      role="presentation"
      onClick={ () => action() }
      onKeyDown={ () => action() }
    > 
      <div style={{ textAlign: "center" }}>
        { usr.role._id === "62be65fcd3c3b30408decc6f" ?
        <img src="/logo-inteliqr.png" style={{ width: "70%" }}/>
        :
        <img src={usr.company.image} style={{ width: "70%", marginTop: 20, marginBottom: 20 }} />
        }
      </div>
      <List>
        {MenuItems.map((item, index) => ( <>
          { item.role.includes(usr.role._id) &&
          <Link to={item.to} key={`${index}-${item.text}`} className={classes.optionDrawer}>
            <ListItem button
              sx={{
                backgroundColor: (location.pathname === item.to) ? "#F3F4F6" : "#383C41",
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                '&:hover': {
                  backgroundColor: (location.pathname === item.to) ? "#F3F4F6" : "#383C41",
                }
              }}
            >
              <ListItemIcon 
                sx={{ 
                  color: (location.pathname === item.to) ? "#383C41" : "#00ffdd"
                }}
              >{item.icon}</ListItemIcon>
              <ListItemText 
                primary={item.text} 
                sx={{ 
                  color: (location.pathname === item.to) ? "#383C41" : "#00ffdd" 
                }} 
              />
            </ListItem>
          </Link>
          }
        </> ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={logOut}>
          <ListItemText primary='Cerrar Sesión' />
        </ListItem>
      </List>
      { usr.role._id !== "62be65fcd3c3b30408decc6f" &&
      <div style={{ textAlign: "center" }}>
        <img src="/logo-inteliqr.png" style={{ width: "40%" }}/>
      </div>
      }
    </Box>
    </>
  );
};
export default Menu;
