import * as Yup from "yup"

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
  menu: Yup.string().required("Campo requerido"),
  url: Yup.string().nullable(),
  duration: Yup.number().typeError("Campo debe ser numérico"),
  header: Yup.object().shape({
    heading: Yup.string().nullable(),
    subheading: Yup.string().nullable(),
    profileImage: Yup.string().nullable(),
  }),
  active: Yup.boolean().required("Campo requerido")
})