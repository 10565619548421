import Stories from 'react-insta-stories'

export default function ReactInstaStories({ data, currentIndex }) {
  return( <>
    { (data !== undefined && data.length > 0) ? 
    <Stories
      stories={data}
      defaultInterval={20000}
      width={432}
      height={768}
      loop={true}
      currentIndex={currentIndex}
    />
    : null }
  </> )
}