import React, { useEffect, useState } from "react";
import { Button, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox';
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { ValidationSchemaStyle } from "../services/SchemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { updateStyle } from "../services/api";
import DialogImageManager from "routes/ImageManager/DialogImageManager";
import 'react-color-gradient-picker/dist/index.css';
import FontSizeModule from "./modules/FontSizeModule";
import CarouselModule from "./modules/CarouselModule";
import BodyMenuModule from "./modules/BodyMenuModule";
import HeaderModule from "./modules/HeaderModule";
import ColorBaseModule from "./modules/ColorBaseModule";
import SeparatorModule from "./modules/SeparatorModule";

const OptionsList = [
  { id: 1, name: "Color base" },
  { id: 2, name: "Header" },
  { id: 3, name: "Separador" },
  { id: 4, name: "Cuerpo del menú" },
  { id: 5, name: "Carousel de categorías" },
  { id: 6, name: "Tamaño de fuente de texto" }
]

export default function Style({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  const [panelToShow, setPanelToShow] = useState(null)
  const [panelName, setPanelName] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchemaStyle)
  })

  useEffect(() => {
    if(open) {
      setDataInstance(instance.style)
      reset(instance.style)
      document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number") { document.activeElement.blur(); }
      });
    }
  }, [open])

  const mutation = useMutation(updateStyle, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error') } 
      else { reportAction(true, 'Acción realizada correctamente', 'success'); handleClose() }
    }
  })

  const handleAdd = (params) => {
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params })
  }
  
  const handleClose = () => { reset(); setPanelToShow(null); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  /* onChange ColorPicker */
  const onChange = (colorAttrs) => {
    setDataInstance({ ...dataInstance, separatorColor: colorAttrs })
    setValue("separatorColor", colorAttrs)
  };

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }  
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const handleChangeCompletePicker = (color, field) => {
    setDataInstance({...dataInstance, [field]: color.hex})
    setValue(field, color.hex)
  }

  const handleChangeDataBoolean = (data) => { setDataInstance({...dataInstance, [data]: !dataInstance[data]}) }

  const Actions = () => (<>
    { !isLoading ? <>
    <Button onClick={handleClose}>Cancelar</Button>
    { panelToShow !== null ? <>
    <Button variant="outlined" color="info" onClick={() => { setPanelToShow(null); setPanelName(null) }}>Atrás</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Editar</Button>
    </>: null }
    </> 
    : <CircularProgress /> 
    }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Editar estilos ${ panelName !== null ? `- ${panelName}` : "" }`} actions={<Actions />}>
      {panelToShow === null && <List>
      { OptionsList.map((optionList, indexOL) => (
        <ListItem key={`${optionList.id}-${indexOL}`} disablePadding>
          <ListItemButton onClick={() => { setPanelToShow(optionList.id); setPanelName(optionList.name); }}>
            <ListItemIcon> <InboxIcon /> </ListItemIcon> <ListItemText primary={optionList.name} />
          </ListItemButton>
        </ListItem>
      )) }
      </List> }
      {panelToShow === 1 &&
        <ColorBaseModule
          dataInstance={dataInstance}
          register={register}
          errors={errors}
          changeDataFunction={handleOnChangeData}
          changeCompletePicker={handleChangeCompletePicker} />
      }
      {panelToShow === 2 && 
        <HeaderModule
          dataInstance={dataInstance}
          register={register}
          errors={errors}
          changeDataFunction={handleOnChangeData}
          setFieldImageManager={handleFieldImageManager}
          openImageManager={handleOpenImageManager}
          changeCompletePicker={handleChangeCompletePicker} />
      }
      {panelToShow === 3 && 
        <SeparatorModule 
          dataInstance={dataInstance}
          register={register}
          errors={errors}
          changeDataFunction={handleOnChangeData}
          changeColorGradient={onChange} />
      }
      {panelToShow === 4 && 
        <BodyMenuModule 
          dataInstance={dataInstance}
          register={register}
          errors={errors}
          changeDataFunction={handleOnChangeData}
          setFieldImageManager={handleFieldImageManager}
          openImageManager={handleOpenImageManager}
          changeCompletePicker={handleChangeCompletePicker} />
      }
      {panelToShow === 5 && 
        <CarouselModule 
          dataInstance={dataInstance}
          register={register}
          errors={errors}
          changeDataBoolean={handleChangeDataBoolean}
          changeDataFunction={handleOnChangeData}
          setFieldImageManager={handleFieldImageManager}
          openImageManager={handleOpenImageManager}
          changeCompletePicker={handleChangeCompletePicker} />
      }
      {panelToShow === 6 && 
        <FontSizeModule 
          dataInstance={dataInstance}
          register={register}
          errors={errors} 
          changeDataFunction={handleOnChangeData} />
      }
    </FlexibleWidthDialog>
    <DialogImageManager open={ imageManagerModalOpen } 
      actionClose={ handleCloseImageManager } 
      setImageSelected={ handleSelectImageManager } />
  </>)
}