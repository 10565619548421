import { useContext, useEffect, useState } from "react";
import { Button, Grid, Toolbar, Box, Container, Tooltip, Fab, IconButton, Divider, MenuItem, FormControlLabel } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, QrCode as QrCodeIcon, FormatColorFill as FormatColorFillIcon, ContentCopy as ContentCopyIcon, Visibility as VisibilityIcon, MoreVert as MoreVertIcon  } from "@mui/icons-material";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "context/AuthContext";
import { getByCompany } from "./services/api";
import Row1 from "components/Rows/Row1";
import Add from "./Add"
import Edit from "./Edit";
import Remove from "./Remove";
import { SnackbarGlobal } from "components/Snackbar";
import ToolBar from "components/ToolBar";
import QR from "./QR";
import Siderbar from "./Siderbar"
import Style from "./Style"
import Duplicate from "./Duplicate";
import { Android12Switch, StyledMenu } from "./styled";

const Menu = () => {
  const { setTitlePage } = useContext(AuthContext);
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete, 4: qrcode, 5: style, 6: duplicate, 7: ver
  const [notification, setNotification] = useState({ open: false, message: "", severity: ""})
  const [companyId, setCompanyId] = useState(null)
  const [companyLogo, setCompanyLogo] = useState("")
  const [instanceSelected, setInstanceSelected] = useState({})
  const [dataFilter, setDataFilter] = useState(null);
  const [openSiderbar, setOpenSiderbar] = useState(true);
  const queryClient = useQueryClient();
  const { /*isLoading, isFetching, error,*/ data } = useQuery(
    ['menusList', companyId], () => getByCompany(companyId), 
    { refetchOnWindowFocus: false, enabled: companyId !== null }
  )

  useEffect(() => { setTitlePage("Menu") }, []);

  useEffect(() => { if(data !== undefined && data !== null && data !== "") setDataFilter(data.data) }, [data])

  const handleRefreshData = () => { queryClient.invalidateQueries('menusList') }

  const handleResetActions = () => { setOpenAction(0) }

  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity})
    if(success) { handleResetActions(); handleRefreshData() }
  }

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event, item) => { setAnchorEl(event.currentTarget); setInstanceSelected(item); }
  const handleClose = () => { setAnchorEl(null) }

  const handlerOpenSiderbar = () => { setOpenSiderbar(true) }
  const handlerCloseSiderbar = () => { setOpenSiderbar(false) }

  const handlerReturnSelectedSiderbar = (value) => { 
    if(value !== null) { setCompanyId(value._id); setCompanyLogo(value.image); }
    else { setCompanyId(null); setCompanyLogo(""); } 
  }

  return ( <>
    <Box component="main">
      <Toolbar />
      <Container>
        <Button onClick={ () => handlerOpenSiderbar() } >Abrir</Button>
        <Siderbar open={ openSiderbar } close={ handlerCloseSiderbar } deep="companies" returnSelected={ handlerReturnSelectedSiderbar }/>
        {(dataFilter !== null && companyId !== null) &&
          <div className="toolbar_container"> <ToolBar searchFunction={ handleSearch } /> </div>
        }
        <Grid container spacing={3}>
        { (dataFilter !== null && dataFilter.length === 0) && <Grid item xs={12}>No hay datos a mostrar</Grid> }
        {(dataFilter !== null && companyId !== null) && 
          <Grid item xs={12}>
            <div className="cus-container">
            { dataFilter.map(( item, index ) => (<>
              <Row1 key={index} 
                image={ companyLogo } 
                info={ <div><b>{ item.name }</b></div> }
                actions={ <div>
                  {/* <FormControlLabel control={<Android12Switch defaultChecked={item.active} />} label="Encendido" /> */}
                  <Tooltip title="Ver menú">
                    <IconButton onClick={ () => { setOpenAction(7); window.open(`https://grupoargentilia.inteliqr.app/menus/${item.slug}`); } }><VisibilityIcon color="success"/></IconButton>
                  </Tooltip>
                  <IconButton
                    id={`demo-customized-button-${item._id}`}
                    aria-controls={open ? `demo-customized-menu-${item._id}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(event) => handleClick(event, item)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <StyledMenu
                    id={`demo-customized-menu-${item._id}`}
                    MenuListProps={{ 'aria-labelledby': `demo-customized-button-${item._id}` }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => { setOpenAction(2); handleClose(); }} disableRipple>
                      <EditIcon/> Editar 
                    </MenuItem>
                    <MenuItem onClick={() => { setOpenAction(4); handleClose(); }} disableRipple>
                      <QrCodeIcon/> Código QR
                    </MenuItem>
                    <MenuItem onClick={() => { setOpenAction(5); handleClose(); }} disableRipple>
                      <FormatColorFillIcon/> Editar estilos
                    </MenuItem>
                    <MenuItem onClick={() => { setOpenAction(6); handleClose(); }} disableRipple>
                      <ContentCopyIcon/> Duplicar
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => { setOpenAction(3); handleClose(); }} disableRipple>
                      <DeleteIcon color="error"/> Eliminar
                    </MenuItem>
                  </StyledMenu>
                </div> }
              />
              { dataFilter.length - 1 !== index && <Divider /> }
            </>))}
            </div>
          </Grid>
        }
        </Grid>
      </Container>
    </Box>
    {(companyId !== null) && <span className="fab_add">
      <Tooltip title="Agregar"><Fab color="primary" onClick={ () => setOpenAction(1) }><AddIcon /></Fab></Tooltip>
    </span> }
    <Add open={ openAction === 1 } close={ () => handleResetActions() } company={ companyId } reportAction={ handleWatchAction }/>
    <Edit open={ openAction === 2 } close={ () => handleResetActions() } company={ companyId } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <Remove open={ openAction === 3 } close={ () => handleResetActions() } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <QR open={ openAction === 4 } close={ () => handleResetActions() } instance={ instanceSelected }/>
    <Style open={ openAction === 5 } close={ () => handleResetActions() } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <Duplicate open={ openAction === 6 } close={ () => handleResetActions() } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={ notification.open } close={ () => setNotification({ open: false, message: "", severity: "" }) } message={ notification.message } severity={ notification.severity }/>
  </> );
};
export default Menu;