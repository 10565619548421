import { useEffect, useRef, useState } from "react";
import { CircularProgress, Fab, IconButton } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { CloudUpload as CloudUploadIcon, Clear as ClearIcon, AddPhotoAlternate as AddPhotoAlternateIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useMutation } from "react-query";
import { saveImage, deleteImage } from "services/api/ImageList";

export default function ImageList({ imageCategory, dataImageList, selectedImageList, setSelectedImageList, returnImageCategory, setDisableMainButtons, reportAction }) {
  const ref = useRef();
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectedImageToUpload, setSelectedImageToUpload] = useState(null)
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImageToUpload) { setImageUrl(URL.createObjectURL(selectedImageToUpload)) }
  }, [selectedImageToUpload]);

  const mutationSave = useMutation(saveImage, {
    onSuccess: (data) => {
      setIsSaving(false)
      setDisableMainButtons(false)
      if(data.status === "error") { reportAction('addImageList', false, { message: 'Error al guardar la imagen'}, false) } else { 
        handleClearImage()
        reportAction('addImageList', false, { }, true) 
      }
    }
  })

  const mutationDelete = useMutation(deleteImage, {
    onSuccess: (data) => {
      setIsDeleting(false)
      setDisableMainButtons(false)
      if(data.status === "error") { reportAction('removeImageList', false, { message: 'Error al eliminar la imagen'}, false) } else { 
        setSelectedImageList(null)
        reportAction('removeImageList', false, { }, true) 
      }
    }
  })

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const file = e.target[0].files;
    if(file[0] === undefined) {
      alert("No fue seleccionado ningun archivo");
      return;
    }
    const formData = new FormData();
    formData.append("file", file[0]);
    setIsSaving(true)
    setDisableMainButtons(true)
    mutationSave.mutate({ id: imageCategory._id, params: formData })
  };

  const handleDeleteImage = () => { 
    setIsDeleting(true)
    setDisableMainButtons(true)
    mutationDelete.mutate({ id: imageCategory._id, params: selectedImageList })
  }

  const handleClearImage = () => { 
    ref.current.value = ""
    setSelectedImageToUpload(null)
    setImageUrl(null);
  }  

  return ( <>
    <div className="im-images-categories-container">
      <div>
        <IconButton size="medium" onClick={() => returnImageCategory()}><ArrowBackIcon /> </IconButton>
        &nbsp; Regresar a categorias <br /><br />
      </div>
      <div className="im-images-container">
      { dataImageList.map((image, index) => 
        <div className={`im-image-card ${ selectedImageList && selectedImageList._id === image._id ? 'im-image-card-selected' : '' }`} key={ index } onClick={ () => setSelectedImageList(image) }>
          <img src={ image.url } alt={ image.id } />
        </div>
      ) }
      </div>
    </div>
    <div className="im-images-actions-container">
    { (selectedImageList === null) ?
      <div className="im-images-actions-upload">
        { imageUrl && selectedImageToUpload && (
          <div> <span>Vista previa:</span> <br /> <img src={imageUrl} alt={selectedImageToUpload.name} style={{ width: "100%", height: "auto", maxWidth: "fit-content" }} /> </div>
        ) }
        <form onSubmit={handleFormSubmit}>
          <input accept="image/*" type="file" ref={ref} name="file" id="select-image" style={{ display: 'none' }} onChange={ e => setSelectedImageToUpload(e.target.files[0]) }/>
          <label htmlFor="select-image">
            { (selectedImageToUpload === null) && <Fab size="small" color="primary" component="span"> <AddPhotoAlternateIcon sx={{ color: "#ffffff" }} /> </Fab> }
          </label>
          { selectedImageToUpload && <>
            { isSaving ? <CircularProgress /> : <>
            <Fab size="small" color="success" type="submit"> <CloudUploadIcon sx={{ color: "#ffffff" }}/> </Fab> &nbsp;
            <Fab size="small" color="primary" onClick={handleClearImage}> <ClearIcon /> </Fab> 
            </> }
          </> }
        </form>
      </div>
      :
      <div className="im-images-actions-delete">
        { isDeleting ? <CircularProgress /> : <>
        <Fab size="small" color="error" onClick={ () => handleDeleteImage() }> <DeleteIcon /> </Fab> &nbsp;
        <Fab size="small" color="primary" onClick={ () => setSelectedImageList(null) }> <ClearIcon /> </Fab>
        </> } <br /><br />
        <span>{ selectedImageList.identifier }</span><br />
        <img src={selectedImageList.url} alt={selectedImageList.identifier} style={{ width: "100%", height: "auto", maxWidth: "fit-content" }} />
      </div>
    }
    </div>
  </> )
}