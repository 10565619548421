import { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, PanToolAlt as PanToolAltIcon } from '@mui/icons-material'
import { useQuery, useQueryClient, useMutation } from "react-query";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getCategoriesByMenu } from "../services/api";
import { updateOrder } from "./services/api";
import ImageCard from "components/Cards/ImageCard";
import { SnackbarGlobal } from "components/Snackbar";
import Add from "./Add";
import Edit from "./Edit";
import Remove from "./Remove";

export default function Categories({ menu, itemSelected }) {
  const [notification, setNotification] = useState({ open: false, message: "", severity: ""})
  const [orderDND, setOrderDND] = useState([])
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete, 4: qrcode
  const [instanceSelected, setInstanceSelected] = useState({})
  const queryClient = useQueryClient();
  const { /*isLoading: iLCompanies, isFetching: iFCompanies, error: eCompanies,*/ data: categoriesByMenu } = useQuery(
    ['categoriesByMenu', menu],
    () => getCategoriesByMenu(menu), 
    { refetchOnWindowFocus: false, enabled: menu !== null && menu !== undefined }
  )

  const handleRefreshData = () => { queryClient.invalidateQueries('categoriesByMenu') }
  const handleResetActions = () => { setOpenAction(0) }
  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity})
    if(success) { handleResetActions(); handleRefreshData() }
  }

  const mutation = useMutation(updateOrder, {
    onSuccess: (data) => {
      //setIsLoading(false)
      if(data.status === "error") { 
        // reportAction(false, 'Error al guardar los datos')
        //console.log('Error al guardar los datos')
      } else { 
        // reportAction(true, 'Acción realizada correctamente')
        //console.log('Acción realizada correctamente')
        handleRefreshData()
      }
    }
  })

  const handlerOrder = (params) => { 
    //console.log("prueba", params)
    //setIsLoading(true)
    mutation.mutate(params)
  }

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var orderDND = categoriesByMenu.data
    const [reorderedItem] = orderDND.splice(droppedItem.source.index, 1);
    orderDND.splice(droppedItem.destination.index, 0, reorderedItem);
    setOrderDND(orderDND)
    let order = []
    orderDND.forEach((element, index) => {
      order.push({ id: element._id, order: index })
    });
    handlerOrder({ value: order })
    ////console.log("order:", order)
  }

  const Actions = ({ item }) => ( <>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(2) } }><EditIcon color="info"/></IconButton>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(3) } }><DeleteIcon color="error"/></IconButton>
    {/* <IconButton onClick={ () => { itemSelected(item._id) } }><PanToolAltIcon color="success"/></IconButton> */}
  </> )

  return ( <>
    <div className="toolbar_container">
      <Grid container spacing={2}>
        <Grid item xs={10}> <Typography variant="h6" gutterBottom sx={{ paddingTop: "4px" }}>Categorías</Typography> </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: "end" }}> <IconButton onClick={() => setOpenAction(1)}><AddIcon /></IconButton> </Grid>
      </Grid>
    </div>
    { categoriesByMenu !== undefined && <>
      { categoriesByMenu.data.length === 0 ? <>
      <Typography variant="body1" gutterBottom>No hay categorías en este menú</Typography>
      </> : 
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          { (provided) => (
            <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
            { categoriesByMenu.data.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(provided) => (
                  <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                    <ImageCard 
                      image={item.image ? item.image : "https://cdn-icons-png.flaticon.com/512/3428/3428655.png"} 
                      name={ <div onClick={ () => { itemSelected(item._id, item.name) } } >{ item.name }</div> } 
                      description={ item.description }
                      active={ item.active }
                      actions={ <Actions item={ item }/> } />
                  </Grid>
                )}
              </Draggable>
            ) ) }
            </Grid>
          ) }
        </Droppable>
      </DragDropContext>
      }
    </> }
    <Add open={ openAction === 1 } close={ () => handleResetActions() } menu={ menu } reportAction={ handleWatchAction }/>
    <Edit open={ openAction === 2 } close={ () => handleResetActions() } menu={ menu } instance={instanceSelected} reportAction={ handleWatchAction }/>
    <Remove open={ openAction === 3 } close={ () => handleResetActions() } instance={instanceSelected} reportAction={ handleWatchAction }/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={ notification.open } close={ () => setNotification({ open: false, message: "", severity: "" }) } message={ notification.message } severity={ notification.severity }/>
  </> )
}