import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  address: Yup.string().nullable(),
  contact: Yup.object().shape({
    email: Yup.string().email("Email inválido").nullable(),
    phone: Yup.string().nullable(),
    social_media: Yup.object().shape({
      whatsapp: Yup.string().nullable(),
      facebook: Yup.string().nullable(),
      instagram: Yup.string().nullable(),
    })
  }),
  image: Yup.string().required("Campo requerido"),
  active: Yup.boolean().required("Campo requerido"),
})