import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().nullable(),
  image: Yup.string().when('showImage', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  video: Yup.string().when('showVideo', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  subcategory: Yup.string().required("Campo requerido"),
  showImage: Yup.boolean().nullable(),
  showVideo: Yup.boolean().nullable(),
  active: Yup.boolean().required("Campo requerido"),
})

// Variants Validations
export const ValidationSchemaVariant = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().nullable(),
  price: Yup.number().typeError("Campo debe ser numérico").required("Campo requerido"),
  image: Yup.string().when('showImage', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  video: Yup.string().when('showVideo', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  calories: Yup.number().typeError("Campo debe ser numérico"),
  showName: Yup.boolean().nullable(),
  showImage: Yup.boolean().nullable(),
  showVideo: Yup.boolean().nullable(),
  active: Yup.boolean().required("Campo requerido"),
})

export const ValidationSchemaVariants = Yup.object({
  variants: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Campo requerido"),
      description: Yup.string().nullable(),
      price: Yup.number().typeError("Campo debe ser numérico").required("Campo requerido"),
      image: Yup.string().when('showImage', {
        is: true,
        then: Yup.string().required("Campo requerido"),
        otherwise: Yup.string().nullable()
      }),
      video: Yup.string().when('showVideo', {
        is: true,
        then: Yup.string().required("Campo requerido"),
        otherwise: Yup.string().nullable()
      }),
      calories: Yup.number().typeError("Campo debe ser numérico"),
      showName: Yup.boolean().nullable(),
      showImage: Yup.boolean().nullable(),
      showVideo: Yup.boolean().nullable(),
      active: Yup.boolean().required("Campo requerido"),
    })
  )
})