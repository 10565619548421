export const DataFields = [
    {
      name: "",
      fields: [
        {
          id: "primaryColor",
          name: "Color base",
          type: "color",
          required: true,
          defaultValue: "#000000",
          size: "small"
        }
      ]
    }
  ]