const gradient = {
  points: [
    { left: 0, red: 0, green: 0, blue: 0, alpha: 1 },
    { left: 100, red: 255, green: 0, blue: 0, alpha: 1 },
  ],
  degree: 0,
  type: 'linear',
};

export const DataFields = [
    {
      name: "",
      fields: [
        {
          id: "separatorSize",
          name: "Tamaño / Altura",
          type: "number",
          required: true,
          defaultValue: 3,
          inputProps: { min: 0, max: 100 },
          endAdornment: { value: "px" },
          size: "small"
        },
        {
          id: "separatorColor",
          name: "Color / Gradiente",
          type: "gradient",
          required: true,
          defaultValue: gradient,
          size: "small"
        }
      ]
    }
  ]