import { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon, ControlPointDuplicate as ControlPointDuplicateIcon, Style as StyleIcon, ArrowBack as ArrowBackIcon, ForkLeft as ForkLeftIcon } from '@mui/icons-material'
import { useQuery, useQueryClient, useMutation } from "react-query";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getProductsBySubcategory } from "./services/api";
import { updateOrder } from "./services/api";
import ImageCard from "components/Cards/ImageCard";
import { SnackbarGlobal } from "components/Snackbar";
import Add from "./Add";
import Edit from "./Edit";
import Variants from "./Variants";
import Tags from "./Tags";
import Remove from "./Remove";
import Migrate from "./Migrate";

export default function Products({ /*data,*/ menu, subcategory, subcategoryName, categoryName, itemSelected, close }) {
  const [notification, setNotification] = useState({ open: false, message: "", severity: ""})
  const [orderDND, setOrderDND] = useState([])
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete, 4: variants, 5: tags, 6: migrate
  const [instanceSelected, setInstanceSelected] = useState({})
  const queryClient = useQueryClient();
  const { /*isLoading: iLCompanies, isFetching: iFCompanies, error: eCompanies,*/ data: productsBySubcategory } = useQuery(
    ['productsBySubcategory', subcategory],
    () => getProductsBySubcategory(subcategory), 
    { refetchOnWindowFocus: false, enabled: subcategory !== null && subcategory !== undefined }
  )

  const handleRefreshData = () => { queryClient.invalidateQueries('productsBySubcategory') }
  const handleResetActions = () => { setOpenAction(0) }
  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity})
    if(success) { handleResetActions(); handleRefreshData() }
  }

  const mutation = useMutation(updateOrder, {
    onSuccess: (data) => {
      //setIsLoading(false)
      if(data.status === "error") { 
        // reportAction(false, 'Error al guardar los datos')
        //console.log('Error al guardar los datos')
      } else { 
        // reportAction(true, 'Acción realizada correctamente')
        //console.log('Acción realizada correctamente')
        handleRefreshData()
      }
    }
  })

  const handlerOrder = (params) => { 
    //console.log("prueba", params)
    //setIsLoading(true)
    mutation.mutate(params)
  }

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var orderDND = productsBySubcategory.data
    const [reorderedItem] = orderDND.splice(droppedItem.source.index, 1);
    orderDND.splice(droppedItem.destination.index, 0, reorderedItem);
    setOrderDND(orderDND)
    let order = []
    orderDND.forEach((element, index) => {
      order.push({ id: element._id, order: index })
    });
    handlerOrder({ value: order })
    ////console.log("order:", order)
  }

  const Actions = ({ item }) => ( <>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(2) } }><EditIcon color="info"/></IconButton>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(3) } }><DeleteIcon color="error"/></IconButton>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(4) } }><ControlPointDuplicateIcon color="success"/></IconButton>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(5) } }><StyleIcon color="success"/></IconButton>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(6) } }><ForkLeftIcon color="success"/></IconButton>
  </> )

  return ( <>
    <div className="toolbar_container">
      <Grid container spacing={2}>
        <Grid item xs={1}> <IconButton onClick={close}><ArrowBackIcon /></IconButton> </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom sx={{ paddingTop: "4px" }}>Productos</Typography>
          <Typography variant="body1" gutterBottom sx={{ paddingTop: "2px" }}>{categoryName} <b>-</b> {subcategoryName}</Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: "end" }}> 
          <IconButton onClick={() => setOpenAction(1)}><AddIcon /></IconButton> 
          <IconButton onClick={close}><CloseIcon /></IconButton> 
        </Grid>
      </Grid>
    </div>
    { productsBySubcategory !== undefined && <>
      { productsBySubcategory.data.length === 0 ? <>
      <Typography variant="body1" gutterBottom>No hay productos en esta subcategoría</Typography>
      </> : 
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          { (provided) => (
            <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
            { productsBySubcategory.data.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(provided) => (
                  <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                    <ImageCard 
                      image={item.image ? item.image : "https://cdn-icons-png.flaticon.com/512/3428/3428655.png"} 
                      name={ item.name } 
                      description={ <>
                        <>{item.description}</>
                        <div style={{ display: "flex" }}>
                          { item.tags_rendered.map((tr, index) => (
                           <div key={index} style={{ padding: "5px 10px", backgroundColor: tr.backgroundColor, color: tr.textColor, textTransform: "uppercase", fontSize: 14, width: "fit-content", borderRadius: 5, fontWeight: "bold", marginRight: 5 }}>
                            {tr.name}
                           </div> 
                          ))
                          }
                        </div>
                      </> }
                      active={ item.active }
                      actions={ <Actions item={ item }/> } />
                  </Grid>
                )}
              </Draggable>
            ) ) }
            </Grid>
          ) }
        </Droppable>
      </DragDropContext>
      }
    </> }
    <Add open={ openAction === 1 } close={ () => handleResetActions() } subcategory={ subcategory } reportAction={ handleWatchAction }/>
    <Edit open={ openAction === 2 } close={ () => handleResetActions() } subcategory={ subcategory } instance={instanceSelected} reportAction={ handleWatchAction }/>
    <Remove open={ openAction === 3 } close={ () => handleResetActions() } instance={instanceSelected} reportAction={ handleWatchAction }/>
    <Variants open={ openAction === 4 } close={ () => handleResetActions() } instance={instanceSelected} reportAction={ handleWatchAction } />
    <Tags open={ openAction === 5 } close={ () => handleResetActions() } instance={instanceSelected} reportAction={ handleWatchAction } />
    <Migrate open={ openAction === 6 } close={ () => handleResetActions() } menu={ menu } instance={instanceSelected} reportAction={ handleWatchAction } />
    <SnackbarGlobal vertical='bottom' horizontal='center' open={ notification.open } close={ () => setNotification({ open: false, message: "", severity: "" }) } message={ notification.message } severity={ notification.severity }/>
  </> )
}