import { Autocomplete, Box, Drawer, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery, useQueryClient } from "react-query";
import { getAll as getAllCompanies } from "routes/Company/services/api";
import { getByCompany } from "routes/Menu/services/api";

export default function Siderbar({ open, close, deep, returnSelected }) {
  const queryClient = useQueryClient();
  const usr = JSON.parse(localStorage.getItem('mqadusr'))
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { isLoading: iLCompanies, isFetching: iFCompanies, error: eCompanies, data: companies } = useQuery(
    ['companies', usr.company ? usr.company._id : ""], 
    () => getAllCompanies(usr.company ? usr.company._id : ""),  
    { refetchOnWindowFocus: false }
  )
  const { isLoading: iLMenus, isFetching: iFMenus, error: eMenus, data: menus } = useQuery(
    ['menus', selectedCompany !== null ? selectedCompany._id : null], 
    () => getByCompany(selectedCompany !== null ? selectedCompany._id : null), 
    { refetchOnWindowFocus: false, enabled: (selectedCompany !== null && deep === "menus") }
  )

  const handlerCompanySelected = (newValue) => { 
    setSelectedCompany(newValue) 
    if(deep === "companies") { returnSelected(newValue) }
  }
  const handlerMenuSelected = (newValue) => { 
    setSelectedMenu(newValue)
    if(deep === "menus") { returnSelected(newValue) }
  }

  const options = () => (
    <Box role="presentation" sx={{ width: 400, pl: 3, pr: 3, pt: 3, pb: 3, }}>
      <Typography variant="h6" gutterBottom> Opciones </Typography><br />
      { (companies !== undefined) && 
        <Grid item xs={12}>
          <InputLabel htmlFor='rds_list' style={{ fontSize: 14 }}>Selecciona una empresa</InputLabel>
          <Autocomplete fullWidth id="rds_list"
          options={ companies.data }
          value={ selectedCompany !== null ? selectedCompany : null }
          getOptionLabel={ (option) => option.name }
          onChange={ (event, newValue) => { newValue !== null ? handlerCompanySelected(newValue) : handlerCompanySelected(null) } }
          renderInput={(params) => ( <TextField {...params} variant="outlined" placeholder="Empresas"/> )} /> <br />
        </Grid>
      }
      { (menus !== undefined) && 
        <Grid item xs={12}>
          <InputLabel htmlFor='menus_list' style={{ fontSize: 14 }}>Selecciona un menu</InputLabel>
          <Autocomplete fullWidth id="menus_list"
          options={ menus.data }
          value={ selectedMenu !== null ? selectedMenu : null }
          getOptionLabel={ (option) => option.name }
          onChange={ (event, newValue) => { newValue !== null ? handlerMenuSelected(newValue) : handlerMenuSelected(null) } }
          renderInput={(params) => ( <TextField {...params} variant="outlined" placeholder="Empresas"/> )} /> <br />
        </Grid>
      }
    </Box>
  );

  return (
    <Drawer anchor='right' open={open} onClose={close}> { options() } </Drawer>
  )
}
