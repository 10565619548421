export const DataFields = [
    {
      name: "",
      fields: [
        {
          id: "fontFaceFile",
          name: "Url fuente de texto",
          type: "text",
          required: true,
          isImage: true,
          defaultValue: "",
          size: "small"
        },
        {
          id: "fontFaceName",
          name: "Nombre fuente de texto",
          type: "text",
          required: true,
          isImage: true,
          defaultValue: "",
          size: "small"
        },
        {
          id: "textColorBody",
          name: "Color de Texto",
          type: "color",
          required: true,
          defaultValue: "#000000",
          size: "small"
        },
        {
          id: "backgroungImageBody",
          name: "Imagen de Fondo",
          type: "image",
          required: true,
          isImage: true,
          defaultValue: "",
          size: "small"
        }
      ]
    }
  ]