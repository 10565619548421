import { useContext, useEffect, useState } from "react";
import { Box, Container, CssBaseline, Divider, Fab, Grid, IconButton, useTheme, useMediaQuery, Toolbar, Tooltip, CardHeader, Avatar, Card, Typography, MenuItem } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Settings as SettingsIcon, MoreVert as MoreVertIcon, MenuBook as MenuBookIcon, Storefront as StorefrontIcon, Visibility as VisibilityIcon } from "@mui/icons-material"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getStoriesByMenu, updateOrder } from "./services/api";
import { AuthContext } from "context/AuthContext";
import Siderbar from "./Siderbar";
import ToolBar from "components/ToolBar";
import { SnackbarGlobal } from "components/Snackbar";
import Remove from "./Remove";
import Add from "./Add";
import { StyledMenu } from "./styled";
import Edit from "./Edit";
import ReactInstaStories from "./modules/ReactInstaStories";

export default function Stories() {
  const { setTitlePage } = useContext(AuthContext)  
  const theme = useTheme();
  const matchesDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [openSiderbar, setOpenSiderbar] = useState(true);
  const [menuIdSelected, setMenuIdSelected] = useState(null);
  const [menuDataSelected, setMenuDataSelected] = useState(null);
  const [orderDND, setOrderDND] = useState([])
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const [instanceSelected, setInstanceSelected] = useState({})
  const [dataFilter, setDataFilter] = useState(null)
  const [storySetIndex, setStorySetIndex] = useState(null)
  const queryClient = useQueryClient()
  const { /* isLoading, isFetching, error, */ data } = useQuery(
    ['storiesByMenu', menuIdSelected],
    () => getStoriesByMenu(menuIdSelected), 
    { refetchOnWindowFocus: false, enabled: menuIdSelected !== null && menuIdSelected !== undefined }
  )

  useEffect(() => { setTitlePage("Historias") }, [])

  useEffect(() => { 
    if(data !== undefined && data !== null && data !== "") setDataFilter(data.data)
  }, [data])

  const handleRefreshData = () => { queryClient.invalidateQueries('storiesByMenu') }
  const handleResetActions = () => { setOpenAction(0) }
  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { handleResetActions(); handleRefreshData() }
  }

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event, item) => { setAnchorEl(event.currentTarget); setInstanceSelected(item); }
  const handleClose = () => { setAnchorEl(null) }

  const handlerOpenSiderbar = () => { setOpenSiderbar(true) }
  const handlerCloseSiderbar = () => { setOpenSiderbar(false) }

  const handlerReturnSelectedSiderbar = (value) => {
    if(value !== null) { setMenuIdSelected(value.menu._id); setMenuDataSelected(value) }
  }

  const mutation = useMutation(updateOrder, {
    onSuccess: (data) => {
      if(data.status === "error") { 
      } else {
        handleRefreshData()
      }
    }
  })

  const handlerOrder = (params) => { mutation.mutate(params) }

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var orderDND = data.data
    const [reorderedItem] = orderDND.splice(droppedItem.source.index, 1);
    orderDND.splice(droppedItem.destination.index, 0, reorderedItem);
    setOrderDND(orderDND)
    setDataFilter(orderDND)
    let order = []
    orderDND.forEach((element, index) => {
      order.push({ id: element._id, order: index })
    });
    handlerOrder({ value: order })
  }

  return(<>
    <CssBaseline />
    <Box component="main" style={{ marginBottom: 65 }}>
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Fab color="secondary" aria-label="settings" sx={{ position: "fixed", top: matchesDownSM ? 25 : 34, right: 25, zIndex: 1101 }} onClick={ () => handlerOpenSiderbar() } >
        <SettingsIcon />
      </Fab>
      <Siderbar open={ openSiderbar } close={ handlerCloseSiderbar } deep="menus" returnSelected={ handlerReturnSelectedSiderbar }/>
      {(dataFilter !== null) && <>
        <div className="toolbar_container"> <ToolBar searchFunction={ handleSearch } /> </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div style={{ marginBottom: 12 }}>
              <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}> <StorefrontIcon />&nbsp; {menuDataSelected.company.name} </Typography>
              <Typography variant="body1" style={{ display: "flex", alignItems: "center" }}> <MenuBookIcon />&nbsp; {menuDataSelected.menu.name} </Typography>
            </div>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="droppable">
              { (provided) => (
                <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                  { dataFilter.map(( item, index ) => (<>
                    <Draggable key={item._id} draggableId={item._id} index={index}>
                      {(provided) => (
                        <Grid key={`${index}-${item._id}`} item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                          <Card>
                            <CardHeader
                              avatar={ <Avatar aria-label="Story" sizes="" sx={{ width: 25, height: 25, bgcolor: item.active ? "#4caf50" : "#ff9800" }}> P </Avatar> }
                              title={item.name}
                              action={ <>
                                <Tooltip title="Ver slide" className="stories-set-index-button">
                                  <IconButton onClick={ () => setStorySetIndex(index) }><VisibilityIcon color="success"/></IconButton>
                                </Tooltip>
                                <IconButton
                                  id={`demo-customized-button-${item._id}`}
                                  aria-controls={open ? `demo-customized-menu-${item._id}` : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  variant="contained"
                                  disableElevation
                                  onClick={(event) => handleClick(event, item)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <StyledMenu
                                  id={`demo-customized-menu-${item._id}`}
                                  MenuListProps={{ 'aria-labelledby': `demo-customized-button-${item._id}` }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  <MenuItem onClick={() => { setOpenAction(2); handleClose(); }} disableRipple>
                                    <EditIcon/> Editar 
                                  </MenuItem>
                                  <Divider sx={{ my: 0.5 }} />
                                  <MenuItem onClick={() => { setOpenAction(3); handleClose(); }} disableRipple>
                                    <DeleteIcon color="error"/> Eliminar
                                  </MenuItem>
                                </StyledMenu>
                              </> }
                            />
                          </Card>
                        </Grid>
                    )}
                    </Draggable>
                  </>))}
                </Grid>
              ) }
              </Droppable>
            </DragDropContext>
          </Grid>
          <Grid item xs={12} md={6} className="stories-preview-container"> 
            { (data !== undefined) ? <ReactInstaStories data={data.data} currentIndex={storySetIndex}/> : null }
          </Grid>
        </Grid>        
      </>}
      </Container>
    </Box>
    {(dataFilter !== null) ? <>
      <span className="stories-preview-fab">
        <Tooltip title="Previsualizar"><Fab color="success" onClick={ () => console.log("Próximamente") }><VisibilityIcon sx={{ color: "#ffffff" }}/></Fab></Tooltip>
      </span> 
      <span className="fab_add">
        <Tooltip title="Agregar"><Fab color="primary" onClick={ () => setOpenAction(1) }><AddIcon /></Fab></Tooltip>
      </span> 
    </> : null }
    <Add open={ openAction === 1 } close={ () => handleResetActions() } menu={ menuIdSelected } reportAction={ handleWatchAction }/>
    <Edit open={ openAction === 2 } close={ () => handleResetActions() } menu={ menuIdSelected } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <Remove open={ openAction === 3 } close={ () => handleResetActions() } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={ notification.open } close={ () => setNotification({ open: false, message: "", severity: "" }) } message={ notification.message } severity={ notification.severity }/>
  </>)
}