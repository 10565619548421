export const DataFields = [
    {
      name: "",
      fields: [
        {
          id: "backgroundColorHeader",
          name: "Color de Fondo",
          type: "color",
          required: true,
          defaultValue: "#000000",
          size: "small"
        },
        {
          id: "backgroungImageHeader",
          name: "Imagen",
          type: "image",
          required: true,
          isImage: true,
          defaultValue: "",
          size: "small"
        }
      ]
    }
  ]