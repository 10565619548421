import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, InputLabel, Switch, TextField } from "@mui/material";
import { Image as ImageIcon } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaColumn } from "../services/SchemaValidation";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import DialogImageManager from "routes/ImageManager/DialogImageManager";

export default function Form({ open, mode, instance, close, addAction, editAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchemaColumn)
  })

  useEffect(() => {
    if(open) {
      if(mode === 1) {
        setDataInstance(instance)
        reset(instance)
      }
    }
  }, [open])

  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handlerActions = (params) => { 
    //console.log("prueba", params)
    if(mode === 0) {
      addAction(params)
    } else {
      editAction(params)
    }
    handleClose()
  }

  const handleOnChangeData = (event) => { 
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({...dataInstance, [name]: !dataInstance[name]}) 
  }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }  
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handlerActions)}>{ mode === 0 ? "Agregar" : "Editar" }</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={ mode === 0 ? "Agregar columna" : "Editar columna" } actions={<Actions />}>
      <>
        <InputLabel htmlFor='title' required style={{ fontSize: 14 }}>Título</InputLabel>
        <TextField id='title' fullWidth margin="dense" type="text" name='title'
          value={dataInstance.title || ""}
          {...register(`title`)} 
          error={errors.title ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.title?.message} />
        <br />
        <InputLabel htmlFor='showTitle' required style={{ fontSize: 14 }}>Mostrar título de la columna?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-showTitle` }}
          onClick={() => handleChangeBoolean('showTitle')}
          checked={dataInstance.showTitle || false} 
          {...register(`showTitle`)} 
        />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataInstance.icon || ""} style={{ width: "90%" }}/>
          </div>
          <div>
            <InputLabel htmlFor='icon' required style={{ fontSize: 14 }}>Icono</InputLabel>
            <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
              value={dataInstance.icon || ""} 
              {...register(`icon`)} error={errors.icon ? true: false}
              onChange={handleOnChangeData} />
            <ValidateErrorMessage message={errors.icon?.message} />
            <IconButton onClick={ () => { handleFieldImageManager('icon'); handleOpenImageManager(); } }><ImageIcon /></IconButton>
          </div>
        </div>
        <br />
        <InputLabel htmlFor='showIcon' required style={{ fontSize: 14 }}>Mostrar ícono?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-showIcon` }}
          onClick={() => handleChangeBoolean('showIcon')}
          checked={dataInstance.showIcon || false} 
          {...register(`showIcon`)} 
        />
        <br />
        <InputLabel htmlFor='active' required style={{ fontSize: 14 }}>Está activa?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={() => handleChangeBoolean('active')}
          checked={dataInstance.active || false} 
          {...register(`active`)} 
        />
      </>
    </FlexibleWidthDialog>
    <DialogImageManager open={ imageManagerModalOpen } 
      actionClose={ handleCloseImageManager } 
      setImageSelected={ handleSelectImageManager } />
  </>)
}