import { useState } from "react";
import { Button, CircularProgress, IconButton, InputLabel, Switch, TextField } from "@mui/material";
import { Image as ImageIcon } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'

import { ValidationSchema } from "../services/SchemaValidation";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { save } from "../services/api";
import DialogImageManager from "routes/ImageManager/DialogImageManager";

export default function Add({ open, close, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  const mutation = useMutation(save, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos')
      } else { reportAction(true, 'Acción realizada correctamente') }
    }
  })

  const handleAdd = (params) => { 
    //console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate(params)
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeActive = () => {
    setDataInstance({...dataInstance, active: !dataInstance.active}) 
  }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }  
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title="Nueva empresa" actions={<Actions />}>
      <>
        <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataInstance.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='address' style={{ fontSize: 14 }}>Dirección</InputLabel>
        <TextField id='address' fullWidth margin="dense" type="text" name='address'
          value={dataInstance.address || ""}
          {...register(`address`)} 
          error={errors.address ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.address?.message} />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataInstance !== undefined ? dataInstance.image : ""} style={{ width: "90%" }}/>
          </div>
          <div>
            <InputLabel htmlFor='image' required style={{ fontSize: 14 }}>Logotipo</InputLabel>
            <TextField id='image' fullWidth margin="dense" type="text" name='image'
              value={dataInstance !== undefined ? dataInstance.image : ""} 
              {...register(`image`)} error={errors.image ? true: false}
              onChange={handleOnChangeData} />
            <ValidateErrorMessage message={errors.image?.message} />
            <IconButton onClick={ () => { handleFieldImageManager('image'); handleOpenImageManager(); } }><ImageIcon /></IconButton>
          </div>
        </div>
        <br />
        <InputLabel htmlFor='email' style={{ fontSize: 14 }}>Correo electrónico</InputLabel>
        <TextField id='email' fullWidth margin="dense" type="text" name='email'
          value={dataInstance.contact ? dataInstance.contact.email : ""}
          {...register(`contact.email`)} 
          error={errors.contact?.email ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.contact?.email?.message} />
        <br />
        <InputLabel htmlFor='phone' style={{ fontSize: 14 }}>Teléfono</InputLabel>
        <TextField id='phone' fullWidth margin="dense" type="number" name='phone'
          value={dataInstance.contact ? dataInstance.contact.phone : ""}
          {...register(`contact.phone`)} 
          error={errors.contact?.phone ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.contact?.phone?.message} />
        <br />
        <InputLabel htmlFor='active' required style={{ fontSize: 14 }}>Está activa?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={handleChangeActive}
          checked={dataInstance.active || false} 
          {...register(`active`)} 
        />
      </>
    </FlexibleWidthDialog>
    <DialogImageManager open={ imageManagerModalOpen } 
      actionClose={ handleCloseImageManager } 
      setImageSelected={ handleSelectImageManager } />
  </>)
}