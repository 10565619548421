import { useEffect, useState } from "react";
import { Button, Checkbox, CircularProgress, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { ValidationSchemaVariants } from "../services/SchemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { getProductTags, updateTags } from "../services/api";
import ToolBar from "components/ToolBar";

export default function Tags({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const queryClient = useQueryClient();
  const { isLoading: iLPT, isFetching: iFPT, /*error: ePT,*/ data: productTags } = useQuery(
    'productTags',
    getProductTags, 
    { refetchOnWindowFocus: false, enabled: open }
  )
  //const [dataInstance, setDataInstance] = useState({})
  //const [itemPosition, setItemPosition] = useState(null)
  let { reset, control, setValue, getValues }  = useForm({ 
    resolver: yupResolver(ValidationSchemaVariants),
    defaultValues: { tags: [] }
  })
  const { fields, append, remove, update, move } = useFieldArray({
    name: 'tags', control: control
  })


  useEffect(() => {
    if(open) {
      //setDataInstance(instance)
      //reset(instance)
      setValue("tags", instance.tags)
      setChecked(instance.tags)
      queryClient.invalidateQueries('productTags')
    }
  }, [open])

  useEffect(() => { 
    if(productTags !== undefined && productTags !== null && productTags !== "") setDataFilter(productTags.data) 
  }, [productTags])

  const mutation = useMutation(updateTags, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleEdit = () => {
    //console.log("prueba", checked)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: { variants: checked } })
  }
  
  const handleClose = () => { setValue("variants", []); close() }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSearch = (e) => {
    const dataFilter = productTags.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleEdit}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} actions={<Actions />} title={`Etiquetas - ${instance.name}`}>
      {(dataFilter !== null /*&& companyId !== null*/) &&
        <div> <ToolBar searchFunction={ handleSearch } /> </div>
      }
      <Grid container spacing={2}>
        <Grid item xs={12}>
        { (iLPT && iFPT) ? <></> : 
        (dataFilter !== undefined &&dataFilter !== null && dataFilter.length === 0) ? <div>No se encontraron etiquetas</div> :
        <>
          <List>
          { dataFilter.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton role={undefined} onClick={ handleToggle(item._id) }>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(item._id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': item._id }}
                  />
                </ListItemIcon>
                <ListItemText id={`checkbox-list-label-${item._id}`} 
                  primary={ <div style={{ backgroundColor: item.backgroundColor, color: item.textColor, padding: "5px 10px",
                    borderRadius: 5 }}>{item.name}</div>} 
                />
              </ListItemButton>
            </ListItem>
          ) ) }
          </List>
        </> }
        </Grid>
      </Grid>
    </FlexibleWidthDialog>
  </>)
}