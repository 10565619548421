import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Header from "./Header";
import Drawer from "./Drawer";
import { AuthContext } from "context/AuthContext";
import { CssBaseline } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" }, 
  content: {
    flexGrow: 1,
    //padding: theme.spacing(3),
  },
  //toolbar: theme.mixins.toolbar,
}))

const Layout = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { isAuth } = useContext(AuthContext)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isAuth) { navigate("/admin") }
  }, [])

  const handlerOpenMenu = () => {
    setOpen(!open);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      { isAuth && <>
      <Header actionMenu={handlerOpenMenu} />
      <Drawer open={open} actionOpen={handlerOpenMenu} />
      </> }
      <main className={classes.content} style={{ width: "100%" }}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
