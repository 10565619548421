import { Dashboard as DashboardIcon } from '@mui/icons-material';

export const MenuItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    to: "/admin/dashboard",
    role: ["62be65fcd3c3b30408decc6f", "6323805edd9642340ca8c012", "63238068dd9642340ca8c013"]
  },
  {
    text: "Empresas",
    icon: <DashboardIcon />,
    to: "/admin/empresas",
    role: ["62be65fcd3c3b30408decc6f"]
  },
  {
    text: "Roles",
    icon: <DashboardIcon />,
    to: "/admin/roles",
    role: ["62be65fcd3c3b30408decc6f"]
  },
  {
    text: "Empleados",
    icon: <DashboardIcon />,
    to: "/admin/empleados",
    role: []
  },
  {
    text: "Menús",
    icon: <DashboardIcon />,
    to: "/admin/menus",
    role: ["62be65fcd3c3b30408decc6f", "6323805edd9642340ca8c012"]
  },
  {
    text: "Etiquetas de Producto",
    icon: <DashboardIcon />,
    to: "/admin/etiquetas-producto",
    role: ["62be65fcd3c3b30408decc6f", "6323805edd9642340ca8c012"]
  },
  {
    text: "Constructor",
    icon: <DashboardIcon />,
    to: "/admin/constructor",
    role: ["62be65fcd3c3b30408decc6f", "6323805edd9642340ca8c012", "63238068dd9642340ca8c013"]
  },
  {
    text: "Promo-Slider",
    icon: <DashboardIcon />,
    to: "/admin/promo-slider",
    role: ["62be65fcd3c3b30408decc6f", "6323805edd9642340ca8c012"]
  },
  /*{
    text: "Categorías",
    icon: <DashboardIcon />,
    to: "/admin/categorias"
  },
  {
    text: "Subcategorías",
    icon: <DashboardIcon />,
    to: "/admin/subcategorias"
  },
  {
    text: "Productos",
    icon: <DashboardIcon />,
    to: "/admin/productos"
  },*/
]