import { API_BASE_URL, HEADERS_WITH_TOKEN } from 'services/api/BaseUrl'

export const getCategoriesByMenu = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/categories/get-by-menu/${id}`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  return await res.json()
}