import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { ValidationSchemaColumns } from "../services/SchemaValidation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { updateColumns } from "../services/api";
import ImageCard from "components/Cards/ImageCard";
import Form from "./Form";

export default function Columns({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [mode, setMode] = useState(null) // 0: add, 1: edit
  const [itemPosition, setItemPosition] = useState(null)
  let { reset, control, setValue, getValues }  = useForm({ 
    resolver: yupResolver(ValidationSchemaColumns),
    defaultValues: { columns: [] }
  })
  const { fields, append, remove, update, move } = useFieldArray({
    name: 'columns', control: control
  })

  useEffect(() => {
    if(open) {
      //setDataInstance(instance)
      //reset(instance)
      setValue("columns", instance.columns)
    }
  }, [open])

  const mutation = useMutation(updateColumns, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleEdit = () => {
    //console.log("prueba", fields)
    setIsLoading(true)
    fields.forEach(field => {
      delete field['id']
    })
    mutation.mutate({ id: instance._id, params: { columns: fields } })
  }
  
  const handleClose = () => { setValue("columns", []); close() }

  const handlerAddItem = (item) => { append(item) }
  const handlerUpdateItem = (item) => { update(itemPosition, item) }
  const handlerRemoveItem = (position) => { remove(position) }

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var data = getValues("columns")
    const [reorderedItem] = data.splice(droppedItem.source.index, 1);
    data.splice(droppedItem.destination.index, 0, reorderedItem);
    setValue("columns", data)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleEdit}>Actualizar columnas</Button>
      </> : <CircularProgress /> }
  </>)

const ActionsColumn = ({ item, position }) => ( <>
  <IconButton onClick={ () => { setItemPosition(position); setDataInstance(item); setMode(1) } }><EditIcon color="info"/></IconButton>
  <IconButton onClick={ () => { handlerRemoveItem(position) } }><DeleteIcon color="error"/></IconButton>
</> )

  return (<>
    <FlexibleWidthDialog open={open} actions={<Actions />}
      title={ <Grid container spacing={2}>
          <Grid item xs={10}> Columnas - {instance.name} </Grid>
          <Grid item xs={2}> <IconButton onClick={() => setMode(0)}><AddIcon /></IconButton> </Grid>
        </Grid> } 
    >
      <Grid container spacing={2}>
        { mode === null ?
        <Grid item xs={12}>
        { fields.length === 0 ? <>
          <Typography variant="body1" gutterBottom>No hay columnas en esta subcategoría</Typography>
          </> : 
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppable">
              { (provided) => (
                <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                { fields.map((item, index) => (
                  <Draggable key={item._id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <ImageCard
                          image={item.icon ? item.icon : "https://cdn-icons-png.flaticon.com/512/3428/3428655.png"} 
                          name={ item.title } 
                          description=""
                          active={ item.active }
                          actions={ <ActionsColumn item={ item } position={index}/> } 
                        />
                      </Grid>
                    )}
                  </Draggable>
                ) ) }
                </Grid>
              ) }
            </Droppable>
          </DragDropContext>
        }
        </Grid> : <Grid item xs={12}> Formulario </Grid> 
        }
      </Grid>
    </FlexibleWidthDialog>
    <Form open={ mode !== null } mode={ mode } instance={ dataInstance } close={ () => setMode(null) } addAction={ handlerAddItem } editAction={ handlerUpdateItem }/>
  </>)
}