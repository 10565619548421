import { useContext, useEffect, useState } from "react";
import { Avatar, Button, CssBaseline, TextField, Box, Typography, Container, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, CircularProgress } from '@mui/material'
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import QrCodeIcon from '@mui/icons-material/QrCode';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import ReCAPTCHA from 'react-google-recaptcha'
import { AuthContext } from 'context/AuthContext'
import { ValidateErrorMessage } from 'components/ValidateErrorMessage'
import ValidationSchema from "./services/SchemaValidation"
import { SnackbarGlobal } from 'components/Snackbar'
import { useMutation } from "react-query";
import { login } from "./services/api/Auth";
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const { activateAuth, loadUser, loadToken, isAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [recaptcha, setRecaptcha] = useState(true);
  let { handleSubmit, register, formState: { errors } }  = useForm({ resolver: yupResolver(ValidationSchema) })

  useEffect(() => {
    if (isAuth) { navigate("/admin/dashboard") }
  })

  const mutation = useMutation(login, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") {
        setMessageSnackbar('Correo y/o contraseña incorrectos');
        setAuthError(true);
      } else {
        loadUser(JSON.stringify(data.user))
        loadToken(data.token)
        activateAuth()
        navigate("/admin/dashboard")
      }
    }
  })

  const onSubmit = (params) => {
    //console.log("Params:", JSON.stringify(params))
    /*if(recaptcha == null) {
      setMessageSnackbar('Es necesario marcar el captcha');
      setAuthError(true); return;
    }*/
    setIsLoading(true)
    mutation.mutate(params)
  }

  const handleClickShowPassword = () => { setShowPassword(!showPassword) }
  const handleMouseDownPassword = (event) => { event.preventDefault() }
  //function onChangeRecaptcha(value) { setRecaptcha(value) }

  return ( <>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 2, bgcolor: "primary.main" }}>
          <QrCodeIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          InteliQR - Acceso
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            required
            fullWidth
            autoFocus
            margin="normal"
            label="Correo electrónico"
            name="email"
            {...register("email")}
            error={errors.email ? true : false}
          />
          <ValidateErrorMessage message={errors.email?.message} />
          <FormControl variant="outlined" fullWidth margin="normal" required>
            <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
            <OutlinedInput required fullWidth
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Contraseña"
              {...register("password")}
              error={errors.password ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  > {showPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <br/> <ReCAPTCHA sitekey="6LemSy8bAAAAABqOAmOu9I6-L2ME8FPAm0ogNcA2" onChange={onChangeRecaptcha} /> <br/> */}
          { (recaptcha !== null) && <>
          {!isLoading ?
          <Button onClick={handleSubmit(onSubmit)} fullWidth variant="contained" color="primary"> Iniciar Sesion </Button>
          : <CircularProgress /> }
          </>}
        </Box>
      </Box>
    </Container>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
  </> );
}