import { useState } from "react";
import { Button, CircularProgress, InputLabel, NativeSelect } from "@mui/material";
import { useMutation, useQuery } from 'react-query'
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { migrate } from "../services/api";
import { getCategoriesByMenu } from "routes/Builder/services/api";
import { getSubcategoriesByCategory } from "../../Subcategories/services/api"

export default function Migrate({ open, close, menu, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [categorySelected, setCategorySelected] = useState("")
  const [subcategorySelected, setSubcategorySelected] = useState("")

  const { data: categoriesByMenu } = useQuery(
    ['categoriesByMenu', menu],
    () => getCategoriesByMenu(menu), 
    { refetchOnWindowFocus: false, enabled: open }
  )

  const { data: subcategoriesByCategory } = useQuery(
    ['subcategoriesByCategory', categorySelected],
    () => getSubcategoriesByCategory(categorySelected), 
    { refetchOnWindowFocus: false, enabled: categorySelected !== "" }
  )
  
  const mutation = useMutation(migrate, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleMigrate = () => { 
    //console.log("prueba", "handleMigrate")
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: { subcategory: subcategorySelected } })
  }

  const handleClose = () => { setSubcategorySelected(""); close() }

  const handleChangeCategory = (e) => {
    //console.log(e.target.value)
    setCategorySelected(e.target.value)
    setSubcategorySelected("")
  }

  const handleChangeSubcategory = (e) => {
    //console.log(e.target.value)
    setSubcategorySelected(e.target.value)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      { subcategorySelected !== "" &&
      <Button variant="contained" color="info" onClick={handleMigrate}>Migrar</Button>
      }
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Migrar producto - ${instance.name}`} actions={<Actions />}>
      <>
        { categoriesByMenu.data !== undefined && <>
        <InputLabel htmlFor='description' style={{ fontSize: 14 }}>Categorías</InputLabel>
        <NativeSelect
          fullWidth
          defaultValue={""}
          onChange={ handleChangeCategory }
        >
          <option value="">Selecciona una opción</option>
        { categoriesByMenu.data.map((cat, index) => (
          <option key={cat._id} value={cat._id}>{cat.name}</option>
        )) }
        </NativeSelect>
        </> }
        <br />
        <br />
        { subcategoriesByCategory !== undefined && <>
        <InputLabel htmlFor='description' style={{ fontSize: 14 }}>Subcategorrías</InputLabel>
        <NativeSelect
          fullWidth
          defaultValue={""}
          onChange={ handleChangeSubcategory }
        >
          <option value="">Selecciona una opción</option>
        { subcategoriesByCategory.data.map((subcat, index) => (
          <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
        )) }
        </NativeSelect>
        </> }
      </>
    </FlexibleWidthDialog>
  </>)
}