import { Container, Divider, Fab, Grid, Toolbar, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Settings as SettingsIcon } from '@mui/icons-material'
import { useState } from "react";
import Siderbar from "./Siderbar";
import Categories from "./Categories";
import Subcategories from "./Subcategories";
import Products from "./Products";

export default function Builder() {
  const theme = useTheme();
  const matchesDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [openSiderbar, setOpenSiderbar] = useState(true);
  const [returnDataSiderbar, setReturnDataSiderbar] = useState(null);
  const [menuIdSelected, setMenuIdSelected] = useState(null);
  const [categoryIdSelected, setCategoryIdSelected] = useState(null);
  const [categoryNameSelected, setCategoryNameSelected] = useState("");
  const [subcategoryIdSelected, setSubcategoryIdSelected] = useState(null);
  const [subcategoryNameSelected, setSubcategoryNameSelected] = useState("");
  const [productIdSelected, setProductIdSelected] = useState(null);
  const [showSections, setShowSections] = useState(null) //0 = Categories, 1 = Subcategories, 2 = Products

  const handlerOpenSiderbar = () => { setOpenSiderbar(true) }
  const handlerCloseSiderbar = () => { setOpenSiderbar(false) }

  const handlerReturnSelectedSiderbar = (value) => { 
    //console.log(value)
    if(value !== null) { setReturnDataSiderbar(value); setMenuIdSelected(value.menu._id); setShowSections(0) }
    else { setReturnDataSiderbar(null) }
  }

  const handlerCategoryIdSelected = (id, name) => {
    // alert(id)
    setCategoryIdSelected(id)
    setCategoryNameSelected(name)
    setShowSections(1)
  }

  const handlerSubcategoryIdSelected = (id, name) => {
    // alert(id)
    setSubcategoryIdSelected(id)
    setSubcategoryNameSelected(name)
    setShowSections(2)
  }

  const handlerProductIdSelected = (id) => {
    // alert(id)
    setProductIdSelected(id)
  }

  return ( <>
    <Toolbar />
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Fab color="secondary" aria-label="settings" sx={{ position: "fixed", top: matchesDownSM ? 25 : 34, right: 25, zIndex: 1101 }} onClick={ () => handlerOpenSiderbar() } >
        <SettingsIcon />
      </Fab>
      <Siderbar open={ openSiderbar } close={ handlerCloseSiderbar } deep="menus" returnSelected={ handlerReturnSelectedSiderbar }/>
      { returnDataSiderbar !== null && <>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom><b>Empresa:</b> { returnDataSiderbar.company.name }</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
        <Typography variant="body1" gutterBottom><b>Menú:</b> { returnDataSiderbar.menu.name }</Typography>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
      {/* Categories */}
      { (showSections === 0 ) && <>
        <Grid item xs={12} style={{ maxWidth: 600, margin: "0 auto" }}>
          <Categories 
            menu={ menuIdSelected } 
            itemSelected={ handlerCategoryIdSelected } 
            isItemSelected={ categoryIdSelected !== null ? true : false } />
        </Grid>
      </> }
      {/* Subcategories */}
      { (showSections === 1) && <>
        <Grid item xs={12} style={{ maxWidth: 600, margin: "0 auto" }}>
          <Subcategories 
            category={ categoryIdSelected } 
            categoryName={ categoryNameSelected }
            itemSelected={ handlerSubcategoryIdSelected } 
            isItemSelected={ categoryIdSelected !== null ? true : false } 
            close={ () => { setSubcategoryIdSelected(null); setShowSections(0) } } />
        </Grid>
      </> }
      {/* Products */}
      { (showSections === 2) && <>
        <Grid item xs={12} style={{ maxWidth: 600, margin: "0 auto" }}>
          <Products 
            menu={ menuIdSelected } 
            subcategory={ subcategoryIdSelected } 
            subcategoryName={ subcategoryNameSelected }
            categoryName={ categoryNameSelected }
            itemSelected={ handlerProductIdSelected } 
            isItemSelected={ categoryIdSelected !== null ? true : false } 
            close={ () => { setProductIdSelected(null); setShowSections(1) } } />
        </Grid>
      </> }
      </Grid>
      </> }
    </Container>
  </> )
}