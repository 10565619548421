import { CssBaseline, Grid, Paper, Typography, Toolbar, Box, Container } from "@mui/material";

const Dashboard = () => {
  return (
    <>
      <CssBaseline />
      <Box component="main">
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className="peperdashboard" sx={{ p: 1 }}>
                <Typography variant="h2" component="h2">Bienvenido</Typography>
                <Typography variant="h6" component="p">
                  Aqui podrás administrar todo los relacionado a los menús de tu empresa
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Dashboard;
