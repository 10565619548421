import React from "react";
import ReactDOM from "react-dom";
import './styles.css'
import './styles/rows.css'
import './styles/stories.css'
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "context/AuthContext";

const queryClient = new QueryClient()

ReactDOM.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </AuthProvider>
  , document.getElementById("root")
);
