import { API_BASE_URL, HEADERS_WITH_TOKEN, HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE } from "./BaseUrl";

export const getImageListByMenu= (id) =>
  fetch(
    `${API_BASE_URL}/image-manager-menu/${id}/image-list`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  .then(res => res.json())

export const saveImage = async ({ id, params }) => {
  const res = await fetch(
    `${API_BASE_URL}/image-manager-menu/${id}/image-list`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE(localStorage.getItem('mqadtk')),
      body: params
    }
  );
  return await res.json();
}

export const deleteImage = async ({id, params}) => {
  const res = await fetch(
    `${API_BASE_URL}/image-manager-menu/${id}/image-list`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  );
  return await res.json();
}