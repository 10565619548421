import { API_BASE_URL, HEADERS_WITH_TOKEN } from 'services/api/BaseUrl'

export const getAll = () =>
  fetch(
    `${API_BASE_URL}/products`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  .then(res => res.json())


export const save = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/products`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const edit = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/products/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const remove = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/products/${id}`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  return await res.json()
}

export const getProductsBySubcategory = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/products/get-by-subcategory/${id}`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  return await res.json()
}

export const updateOrder = async (data) => {
  const res = await fetch(
    `${API_BASE_URL}/products/update-order`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(data)
    }
  )
  return await res.json()
}

export const updateVariants = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/products/update-variants/${id}`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const updateTags = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/products/update-tags/${id}`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const migrate = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/products/migrate/${id}`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const getProductTags = () =>
  fetch(
    `${API_BASE_URL}/product-tags`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  .then(res => res.json())