import React from 'react'
import { Snackbar } from "@mui/material"
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarGlobal = ({ vertical, horizontal, open, close, message, severity }) => {

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={open}
      autoHideDuration={5000}
      onClose={close}
      key={`${vertical}-${horizontal}-${message}`}
    >
      <Alert onClose={close} severity={severity} sx={{ width: "100%" }}> { message } </Alert>
    </Snackbar>
  )
}