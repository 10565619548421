import { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, InputLabel, Switch, TextField } from "@mui/material";
import { Image as ImageIcon } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { ValidationSchema } from "../services/SchemaValidation";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { edit } from "../services/api";
import DialogImageManager from "routes/ImageManager/DialogImageManager";

export default function Edit({ open, close, subcategory, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  useEffect(() => {
    if(open) {
      setDataInstance(instance)
      reset(instance)
    }
  }, [open])

  const mutation = useMutation(edit, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleEdit = (params) => { 
    //console.log("prueba", params)
    setIsLoading(true)
    let id = params._id
    delete params._id
    delete params.tags_rendered
    delete params.deleted
    delete params.createdAt
    delete params.updatedAt
    mutation.mutate({ id, params })
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({...dataInstance, [name]: !dataInstance[name]}) 
  }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }  
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Editar producto - ${instance.name}`} actions={<Actions />}>
      <>
        <input type="hidden" name="subcategory" {...register(`subcategory`)} value={subcategory}/>
        <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataInstance.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='description' style={{ fontSize: 14 }}>Descripción</InputLabel>
        <TextField id='description' fullWidth margin="dense" type="text" name='description' multiline maxRows={5}
          value={dataInstance.description || ""}
          {...register(`description`)} 
          error={errors.description ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.description?.message} />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataInstance.image || ""} style={{ width: "90%" }}/>
          </div>
          <div>
            <InputLabel htmlFor='image' required style={{ fontSize: 14 }}>Imagen</InputLabel>
            <TextField id='image' fullWidth margin="dense" type="text" name='image'
              value={dataInstance.image || ""} 
              {...register(`image`)} error={errors.image ? true: false}
              onChange={handleOnChangeData} />
            <ValidateErrorMessage message={errors.image?.message} />
            <IconButton onClick={ () => { handleFieldImageManager('image'); handleOpenImageManager(); } }><ImageIcon /></IconButton>
          </div>
        </div>
        <br />
        <InputLabel htmlFor='showImage' required style={{ fontSize: 14 }}>Mostrar imagen?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-showImage` }}
          onClick={() => handleChangeBoolean('showImage')}
          checked={dataInstance.showImage || false} 
          {...register(`showImage`)} 
        />
        <br />
        <InputLabel htmlFor='active' required style={{ fontSize: 14 }}>Está activa?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={() => handleChangeBoolean('active')}
          checked={dataInstance.active || false} 
          {...register(`active`)} 
        />
      </>
    </FlexibleWidthDialog>
    <DialogImageManager open={ imageManagerModalOpen } 
      actionClose={ handleCloseImageManager } 
      setImageSelected={ handleSelectImageManager } />
  </>)
}