import { useState, useEffect } from "react";
import { Button, IconButton, InputLabel, TextField, Typography } from "@mui/material";
import { Folder as FolderIcon, CreateNewFolder as CreateNewFolderIcon } from '@mui/icons-material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "react-query";
import { saveImageMenu } from "services/api/ImageManagerMenu";
import ToolBar from "components/ToolBar";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import ImageCategorySchema from "../services/ImageCategorySchemaValidation";

export default function ImageCategory({ dataImageCategories, selectedImageCategory, setSelectedImageCategory, imageList, setImageList, reportAction }) {
  const [isLoading, setIsLoading] = useState(null);
  const [instanceImageCategory, setInstanceImageCategory] = useState({ name: '', description: '' });
  const [dataImageCategoryFilter, setDataImageCategoryFilter] = useState(null);
  const [modeImageCategory, setModeImageCategory] = useState(null); //true: add, false: edit
  let { reset: resetIM, handleSubmit: handleSubmitIM, register: registerIM, formState: { errors: errorsIM }/*, setValue: setValueIM*/ } = useForm({ resolver: yupResolver(ImageCategorySchema) })
  
  useEffect(() => { 
    if(dataImageCategories !== undefined && dataImageCategories !== null && dataImageCategories !== "")
      setDataImageCategoryFilter(dataImageCategories.data) 
  }, [dataImageCategories])

  const mutation = useMutation(saveImageMenu, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('addImageCategory', false, { message: 'Error al editar los datos'}, false) } else { 
        handleCancel();
        reportAction('addImageCategory', false, { }, true) 
      }
    }
  })

  const handleSubmitNewImageCategory = (params) => { //console.log("New Image Category", params)
    setIsLoading(true)
    mutation.mutate(params)
  }

  const handleCancel = () => { resetIM(); setInstanceImageCategory({}); setModeImageCategory(null); }

  const handleSelectImageCategory = (imageCategory) => { 
    setSelectedImageCategory(imageCategory)
    setImageList(imageCategory.image_list)
  }

  const handleSearchImageCategory = (e) => {
    const dataFilter = dataImageCategories.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataImageCategoryFilter(dataFilter)
  }

  const handleSetModeImageCategory = (mode) => { setModeImageCategory(mode) }

  const handleChangeData = (event) => { 
    setInstanceImageCategory({...instanceImageCategory, [event.target.name]: event.target.value}) 
  }

  return ( <>
    <div className="im-images-categories-container">
    { (dataImageCategoryFilter !== null && imageList === null) && <>
      <div className="im-images-toobox-container"> <ToolBar searchFunction={handleSearchImageCategory} /> </div>
      <div className="im-images-container">        
      { dataImageCategoryFilter.map((category, index) => 
        <div className={`im-image-category ${ selectedImageCategory && selectedImageCategory._id === category._id ? 'im-image-category-selected' : '' }`} key={ index } onClick={ () => handleSelectImageCategory(category) }>
          <FolderIcon sx={{ fontSize: 40 }} color="secondary" /> <span>{ category.name }</span>
        </div>
      ) }
      </div>
    </> }
    </div>
    <div className="im-images-actions-container">
    { (modeImageCategory === null) ?
      <IconButton onClick={ () => handleSetModeImageCategory(true) }> <CreateNewFolderIcon /> </IconButton>
      : <> 
      <Typography variant="inherit"> { modeImageCategory ? "Agregar" : "Editar" } categoría </Typography>
      <InputLabel htmlFor='name' required style={{ fontSize: 14 }}> Nombre </InputLabel>
      <TextField id='name' fullWidth margin="dense" type="text" name='name'
        value={ instanceImageCategory.name || "" }
        { ...registerIM(`name`) } 
        error={ errorsIM.name ? true: false }
        onChange={ handleChangeData } />
      <ValidateErrorMessage message={ errorsIM.name?.message } /><br />
      <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
      <TextField id='description' fullWidth margin="dense" type="text" name='description' multiline maxRows={4}
        value={ instanceImageCategory.description || "" }
        { ...registerIM(`description`) } 
        error={ errorsIM.description ? true: false }
        onChange={ handleChangeData } />
      <ValidateErrorMessage message={errorsIM.description?.message} /><br />
      { !isLoading ? <div>
        <Button onClick={ () => handleCancel() } variant="outlined" color="primary"> Cancelar </Button> &nbsp;
        { (!modeImageCategory) ? <>
          <Button onClick={ () => alert("Actualizar") } variant="contained" color="secondary"> Actualizar </Button>
        </> : <Button onClick={ handleSubmitIM(handleSubmitNewImageCategory) } variant="contained" color="primary"> Agregar </Button> }
      </div> : <></> }
      </>
    }
    </div>
  </> )
}