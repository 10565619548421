import { useState } from "react";
import { Button, CircularProgress, InputLabel, Switch, TextField } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'

import { ValidationSchema } from "../services/SchemaValidation";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { save } from "../services/api";

export default function Add({ open, close, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors } }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  const mutation = useMutation(save, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos')
      } else { reportAction(true, 'Acción realizada correctamente') }
    }
  })

  const handleAdd = (params) => { 
    //console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate(params)
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeActive = () => {
    setDataInstance({...dataInstance, active: !dataInstance.active}) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title="Nueva etiqueta" actions={<Actions />}>
      <>
        <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataInstance.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='textColor' required style={{ fontSize: 14 }}>Color del Texto</InputLabel>
        <TextField id='textColor' fullWidth margin="dense" type="text" name='textColor'
          value={dataInstance.textColor || ""}
          {...register(`textColor`)} 
          error={errors.textColor ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.textColor?.message} />
        <br />
        <InputLabel htmlFor='backgroundColor' required style={{ fontSize: 14 }}>Color de Fondo</InputLabel>
        <TextField id='backgroundColor' fullWidth margin="dense" type="text" name='backgroundColor'
          value={dataInstance.backgroundColor || ""}
          {...register(`backgroundColor`)} 
          error={errors.backgroundColor ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.backgroundColor?.message} />
        <br />
        <InputLabel htmlFor='active' required style={{ fontSize: 14 }}>Está activa?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={handleChangeActive}
          checked={dataInstance.active || false} 
          {...register(`active`)} 
        />
      </>
    </FlexibleWidthDialog>
  </>)
}