import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().nullable(),
  footer: Yup.string().nullable(),
  icon: Yup.string().nullable(),
  image: Yup.string().when('showImage', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  video: Yup.string().when('showVideo', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  category: Yup.string().required("Campo requerido"),
  showName: Yup.boolean().nullable(),
  showImage: Yup.boolean().nullable(),
  showVideo: Yup.boolean().nullable(),
  presentation: Yup.number().required(),
  active: Yup.boolean().required("Campo requerido"),
})

// Columns Validations
export const ValidationSchemaColumn = Yup.object().shape({
  title: Yup.string().required("Campo requerido"),
  icon: Yup.string().when('showIcon', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),  
  showTitle: Yup.boolean().nullable(),
  showIcon: Yup.boolean().nullable(),
  active: Yup.boolean().required("Campo requerido")
})

export const ValidationSchemaColumns = Yup.object({
  variants: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Campo requerido"),
      icon: Yup.string().when('showIcon', {
        is: true,
        then: Yup.string().required("Campo requerido"),
        otherwise: Yup.string().nullable()
      }),  
      showTitle: Yup.boolean().nullable(),
      showIcon: Yup.boolean().nullable(),
      active: Yup.boolean().required("Campo requerido")
    })
  )
})