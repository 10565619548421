import { API_BASE_URL, HEADERS_WITH_TOKEN } from "services/api/BaseUrl"

export const getAll = () =>
  fetch(
    `${API_BASE_URL}/stories`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  .then(res => res.json())

  export const save = async (params) => {
    const res = await fetch(
      `${API_BASE_URL}/stories`,
      {
        method: 'POST',
        headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
        body: JSON.stringify(params)
      }
    )
    return await res.json()
  }
  
  export const edit = async (data) => {
    const { id, params } = data
    const res = await fetch(
      `${API_BASE_URL}/stories/${id}`,
      {
        method: 'PUT',
        headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
        body: JSON.stringify(params)
      }
    )
    return await res.json()
  }
  
  export const remove = async (id) => {
    const res = await fetch(
      `${API_BASE_URL}/stories/${id}`,
      {
        method: 'DELETE',
        headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
      }
    )
    return await res.json()
  }

  export const updateOrder = async (data) => {
    const res = await fetch(
      `${API_BASE_URL}/stories/update-order`,
      {
        method: 'POST',
        headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk')),
        body: JSON.stringify(data)
      }
    )
    return await res.json()
  }

  export const getStoriesByMenu = async (id) => {
    const res = await fetch(
      `${API_BASE_URL}/stories/get-by-menu/${id}`,
      {
        method: 'GET',
        headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
      }
    )
    return await res.json()
  }