import { useContext, useEffect, useState } from "react";
import { CssBaseline, Grid, Toolbar, Box, Container, Tooltip, Fab, IconButton } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "context/AuthContext";
import { getAll } from "./services/api";
import Card1 from "components/Cards/Card1";
import Add from "./Add"
import Edit from "./Edit";
import Remove from "./Remove";
import { SnackbarGlobal } from "components/Snackbar";

const Company = () => {
  const { setTitlePage } = useContext(AuthContext);
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [notification, setNotification] = useState({ open: false, message: "", })
  const [instanceSelected, setInstanceSelected] = useState({})
  const queryClient = useQueryClient();
  const { isLoading, isFetching, error, data } = useQuery(
    ['companiesList', ""], 
    () => getAll(""),  
    { refetchOnWindowFocus: false }
  )

  useEffect(() => { setTitlePage("Empresas") }, []);

  const handleRefreshData = () => { queryClient.invalidateQueries('companiesList') }

  const handleResetActions = () => { setOpenAction(0) }

  const handleWatchAction = (success, message) => {
    setNotification({ open: true, message: message, })
    if(success) { handleResetActions(); handleRefreshData() }
  }

  const Actions = ({ item }) => ( <>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(2)  } } ><EditIcon color="info"/></IconButton>
    <IconButton onClick={ () => { setInstanceSelected(item); setOpenAction(3)  } }><DeleteIcon color="error"/></IconButton>
  </> )

  if(isLoading || isFetching) return <div>Loading...</div>
  if(error) return <div>A ocurrido un error</div>

  return ( <>
    <CssBaseline />
    <Box component="main">
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            { data.data.map(( item, index ) => (
              <Card1 key={index} 
                image={ item.image } 
                info={ <>
                  <div><b>{ item.name }</b></div>
                  <div>Email: { item.contact.email }</div>
                  <div>Teléfono: { item.contact.phone }</div>
                </> }
                actions={ <Actions item={ item }/> }
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
    <span className="fab_add">
      <Tooltip title="Agregar"><Fab color="primary" onClick={ () => setOpenAction(1) }><AddIcon /></Fab></Tooltip>
    </span>
    <Add open={ openAction === 1 } close={ () => handleResetActions() } reportAction={ handleWatchAction }/>
    <Edit open={ openAction === 2 } close={ () => handleResetActions() } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <Remove open={ openAction === 3 } close={ () => handleResetActions() } instance={ instanceSelected } reportAction={ handleWatchAction }/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={ notification.open } close={ () => setNotification({ open: false, message: "" }) } message={ notification.message } />
  </> );
};
export default Company;