import { IconButton, InputAdornment, InputLabel, Switch, TextField } from "@mui/material"
import { Image as ImageIcon } from "@mui/icons-material"
import { SketchPicker } from "react-color"
import { ColorPicker } from 'react-color-gradient-picker';

export const TextInputC = ({
  id,
  name,
  type,
  required,
  defaultValue,
  inputProps,
  endAdornment,
  startAdornment,
  size,
  instance,
  checkIfPropertyIsInInstance,
  register,
  errors,
  changeDataFunction
}) => {  

  if(id === undefined || id === null) {
    return <p>Falta el atributo id</p>
  } else if(name === undefined || name === null) {
    return <p>Falta el atributo name</p>
  } else if(type === undefined || type === null) {
    return <p>Falta el atributo type</p>
  } else if(defaultValue === undefined || defaultValue === null) {
    return <p>Falta el atributo defaultValue</p>
  }

  return (<>
    <InputLabel htmlFor={id} required={required} style={{ fontSize: 14 }}>{name}</InputLabel>
    <TextField 
      fullWidth 
      id={id} 
      margin="dense" 
      type={type} 
      size={(size !== undefined && size !== null) ? size : "size"} 
      name={id} 
      InputProps={{
        inputProps: (inputProps !== undefined && inputProps !== null) ? inputProps : {}, 
        endAdornment: (endAdornment !== undefined && endAdornment !== null) ? <InputAdornment position="end">{endAdornment.value}</InputAdornment> : null,
        startAdornment: (startAdornment !== undefined && startAdornment !== null) ? <InputAdornment position="start">{startAdornment.value}</InputAdornment> : null
      }}
      value={checkIfPropertyIsInInstance(id) ? instance[id] : defaultValue}
      {...register(id)} 
      error={errors[id] ? true: false}
      onChange={changeDataFunction} />
  </>)
}

export const SwitchC = ({
  id,
  name,
  required,
  instance,
  checkIfPropertyIsInInstance,
  register,
  changeDataFunction
}) => {

  if(id === undefined || id === null) {
    return <>Falta el atributo id</>
  } else if(name === undefined || name === null) {
    return <p>Falta el atributo name</p>
  }

  return (<>
    <InputLabel htmlFor={id} required={required} style={{ fontSize: 14 }}>{name}</InputLabel>
    <Switch edge="end" required={required} inputProps={{ 'aria-labelledby': `switch-list-label-${id}` }}
      onClick={() => changeDataFunction(id)}
      checked={checkIfPropertyIsInInstance(id) ? instance[id] : false} 
      {...register(id)} 
    />
  </>)
}

export const ImageInputC = ({
  id,
  name,
  type,
  required,
  defaultValue,
  size,
  instance,
  checkIfPropertyIsInInstance,
  register,
  errors,
  changeDataFunction,
  setFieldImageManager,
  openImageManager
}) => {

  if(id === undefined || id === null) {
    return <p>Falta el atributo id</p>
  } else if(name === undefined || name === null) {
    return <p>Falta el atributo name</p>
  } else if(type === undefined || type === null) {
    return <p>Falta el atributo type</p>
  } else if(defaultValue === undefined || defaultValue === null) {
    return <p>Falta el atributo defaultValue</p>
  }

  return (
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
      <div style={{ textAlign: "center" }}>
        <img src={instance !== undefined ? instance[id] : ""} style={{ width: "90%" }}/>
      </div>
      <div>
        <InputLabel htmlFor={id} required={required} style={{ fontSize: 14 }}>{name}</InputLabel>
        <TextField 
        fullWidth 
        id={id} 
        margin="dense" 
        type="text" 
        size={(size !== undefined && size !== null) ? size : "size"} 
        name={id}
        value={checkIfPropertyIsInInstance(id) ? instance[id] : defaultValue}
        {...register(id)} 
        error={errors[id] ? true: false}
        onChange={changeDataFunction} />
        <IconButton onClick={ () => { setFieldImageManager(id); openImageManager(); } }><ImageIcon /></IconButton>        
      </div>
    </div>
  )
}

export const ColorInputC = ({
  id,
  name,
  type,
  required,
  defaultValue,
  size,
  instance,
  checkIfPropertyIsInInstance,
  register,
  errors,
  changeDataFunction,
  changeCompletePicker
}) => {

  if(id === undefined || id === null) {
    return <p>Falta el atributo id</p>
  } else if(name === undefined || name === null) {
    return <p>Falta el atributo name</p>
  } else if(type === undefined || type === null) {
    return <p>Falta el atributo type</p>
  } else if(defaultValue === undefined || defaultValue === null) {
    return <p>Falta el atributo defaultValue</p>
  }

  return(<>
    <InputLabel htmlFor={id} required={required} style={{ fontSize: 14 }}>{name}</InputLabel>
    <TextField 
      fullWidth 
      id={id} 
      margin="dense" 
      type="text" 
      size={(size !== undefined && size !== null) ? size : "size"} 
      name={id}
      value={checkIfPropertyIsInInstance(id) ? instance[id] : defaultValue}
      {...register(id)} 
      error={errors[id] ? true: false}
      onChange={changeDataFunction}
      sx={{
        input: {
          color: "#a3a3a3",
          backgroundColor: checkIfPropertyIsInInstance(id) ? instance[id] : defaultValue,
        }
      }}
      />
      <SketchPicker presetColors={[]}
        color={checkIfPropertyIsInInstance(id) ? instance[id] : defaultValue}
        onChangeComplete={ (color) => changeCompletePicker(color, id) }
      />
  </>)
}

export const ColorGradientInputC = ({
  id,
  name,
  type,
  required,
  defaultValue,
  size = 3,
  instance,
  checkIfPropertyIsInInstance,
  changeColorGradient
}) => {
  if(id === undefined || id === null) {
    return <p>Falta el atributo id</p>
  } else if(name === undefined || name === null) {
    return <p>Falta el atributo name</p>
  } else if(defaultValue === undefined || defaultValue === null) {
    return <p>Falta el atributo defaultValue</p>
  }

  return (<>
    <InputLabel htmlFor={id} required={required} style={{ fontSize: 14 }}>{name}</InputLabel>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ColorPicker onStartChange={changeColorGradient} onChange={changeColorGradient} onEndChange={changeColorGradient} gradient={checkIfPropertyIsInInstance(id) ? instance[id] : defaultValue} isGradient
      />
    </div>
  </>)
}