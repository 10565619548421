export const DataFields = [
    {
      name: "",
      fields: [
        {
          id: "backgroungImageCategories",
          name: "Imagen de Fondo",
          type: "image",
          required: true,
          isImage: true,
          defaultValue: "",
          size: "small"
        },
        {
          id: "textColorCategories",
          name: "Color de Texto",
          type: "color",
          required: true,
          defaultValue: "#000000",
          size: "small"
        },
        {
          id: "activeIndicatorColorCategories",
          name: "Color de indicador de categoría activa",
          type: "color",
          required: true,
          defaultValue: "#000000",
          size: "small"
        },
        {
          id: "colorArrowsCategories",
          name: "Color flechas de carousel",
          type: "color",
          required: true,
          defaultValue: "#000000",
          size: "small"
        },
        {
          id: "colorDotsCategories",
          name: "Color dots de carousel",
          type: "color",
          required: true,
          defaultValue: "#000000",
          size: "small"
        },
        {
          id: "isSameFontFaceBody",
          name: "Misma fuente de texto que en el cuerpo del menú?",
          type: "switch",
          required: true,
          defaultValue: false,
          size: "small"
        },
        {
          id: "fontSizePCTextCategories",
          name: "Tamaño de texto en PC",
          type: "number",
          required: true,
          defaultValue: 38,
          inputProps: { min: 10, max: 60 },
          endAdornment: { value: "px" },
          startAdornment: null,
          size: "small"
        },
        {
          id: "fontSizeMovilTextCategories",
          name: "Tamaño de texto en Móvil",
          type: "number",
          required: true,
          defaultValue: 24,
          inputProps: { min: 10, max: 60 },
          endAdornment: { value: "px" },
          startAdornment: null,
          size: "small"
        },
        {
          id: "fontFaceFileCategories",
          name: "Url fuente de texto",
          type: "text",
          required: true,
          isImage: true,
          defaultValue: "",
          size: "small"
        },
        {
          id: "fontFaceNameCategories",
          name: "Nombre fuente de texto",
          type: "text",
          required: true,
          isImage: true,
          defaultValue: "",
          size: "small"
        }
      ]
    }
  ]